import React, { FC, useEffect, useState } from "react";
import { format } from "date-fns";
import ReactPlayer from "react-player";
import { SwiperOptions } from "swiper/types/swiper-options";
import { Link, navigate, useLocation } from "@reach/router";

import {
    AppCard,
    AppButton,
    AppTextHoverTooltip,
    AppQuMuPlayer,
    AppVideoPlayer,
    AppContentModal,
    AppUsersList,
} from "..";
import { CommonModelCardProps, User, VideoGallery } from "../../models";
import { useBuildAssetPath, useTranslation } from "../../hooks";
import { getETrans, isString, resolveImageWithStyle } from "../../utils";
import placeHolderImage from "../../assets/images/placeholders/img-thumb.svg";
import {
    AppUserListDisplayLength,
    ShowMoreTextCharLength,
    STREAM_TYPE,
    VideoGalleryPosterFileInfo,
    VideoGalleryVideoFileInfo,
} from "../../../config";

import "./assets/scss/style.scss";

export interface VideoCardProps extends CommonModelCardProps<VideoGallery> {
    options?: SwiperOptions;
}

export const VideoCard: FC<VideoCardProps> = ({
    item,
    className = "",
    isListView = false,
}): JSX.Element => {
    const {
        id,
        imageName = "",
        isTop,
        videoGalleryCategory,
        totalViews = 0,
        createdAt,
        container,
        streamType,
        videoGalleryTags,
        speakers,
        moderators,
    } = item;

    const title = getETrans(item, "title");
    const description = getETrans(item, "description");
    const streamUrl = getETrans(item, "streamUrl");

    // hooks
    const { t } = useTranslation();

    // query params
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const elementId = query.get("id");

    // image
    const basePath = useBuildAssetPath(VideoGalleryPosterFileInfo, container);
    const style = resolveImageWithStyle(basePath, imageName, placeHolderImage);

    const videoBasePath = useBuildAssetPath(
        VideoGalleryVideoFileInfo,
        container
    );

    // state
    const [isHoveringId, setIsHoveringId] = useState<number>(0);
    const [isMuted, setIsMuted] = useState(true);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [showMore, setShowMore] = useState<boolean>(false);
    const [showMoreDesc, setShowMoreDesc] = useState<boolean>(false);
    const [isPopUp, setIsPopUp] = useState<boolean>(false);

    useEffect(() => {
        if (Number(elementId) === id) {
            setIsPopUp(true);
        }
    }, []);

    const dateText = (date: string | Date) => {
        if (!date || !isString(date)) {
            return "";
        }

        const today = new Date();
        const yesterday = new Date(today.getTime() - 86400000);

        switch (date.substring(0, 10)) {
            case format(today, "yyyy-MM-dd"):
                return t("messagebox:datechat.title.today");
            case format(yesterday, "yyyy-MM-dd"):
                return t("messagebox:datechat.title.yesterday");
            default:
                format(new Date(date), "dd MMMM, yyyy");
        }

        return format(new Date(date), "dd MMMM, yyyy");
    };

    const getUrl = () => {
        if (!id || !streamUrl) {
            return "";
        }

        let url = `${videoBasePath}/${streamUrl}`;

        if (streamType !== STREAM_TYPE.STREAMTYPE_FILE) {
            url = streamUrl;
        }

        if (!url) {
            url = "";
        }
        return url;
    };

    const getId = () => {
        return `app-video-player-${id}`;
    };

    const changeMuted = (value: boolean) => {
        const videoElement = document.getElementById(
            getId()
        ) as HTMLVideoElement;

        if (videoElement) {
            videoElement.muted = value;
        }
    };

    const toggleMute = () => {
        changeMuted(!isMuted);
        setIsMuted(!isMuted);
    };

    const playVideo = () => {
        if (!getUrl()) {
            return;
        }
        setIsPopUp(false);
        setShowFullScreen(true);
    };

    const getVideoTags = () => {
        return showMore
            ? videoGalleryTags
            : videoGalleryTags.slice(0, AppUserListDisplayLength);
    };

    const renderCategoryTag = () =>
        videoGalleryCategory && (
            <div className="category">
                <div
                    className="content"
                    style={{
                        "--video-category-background-color":
                            videoGalleryCategory?.color,
                        backgroundColor: videoGalleryCategory?.color,
                    }}
                >
                    <span
                        style={{
                            backgroundColor: videoGalleryCategory?.color,
                            color: videoGalleryCategory?.textColor,
                        }}
                    >
                        {getETrans(videoGalleryCategory, "name")}
                    </span>
                </div>
            </div>
        );

    const renderStreamPlayer = () => (
        <>
            {streamType === STREAM_TYPE.STREAMTYPE_QUMU && (
                <AppQuMuPlayer className="qumu-gallery" url={getUrl()} />
            )}

            {streamType === STREAM_TYPE.STREAMTYPE_SWISSCOM && (
                <iframe
                    id="wbc-iframe"
                    width="100%"
                    src={getUrl()}
                    allow="fullscreen"
                    allowFullScreen
                    style={{
                        aspectRatio: "1.77",
                        border: "none",
                    }}
                ></iframe>
            )}

            {streamType === STREAM_TYPE.STREAMTYPE_FILE && (
                <AppVideoPlayer
                    id={getId()}
                    url={getUrl()}
                    autoPlay={true}
                    muted={true}
                    controls={false}
                />
            )}

            {streamType !== STREAM_TYPE.STREAMTYPE_FILE &&
                streamType !== STREAM_TYPE.STREAMTYPE_SWISSCOM &&
                streamType !== STREAM_TYPE.STREAMTYPE_QUMU && (
                    <ReactPlayer
                        url={getUrl()}
                        controls
                        playing
                        muted
                        width="100%"
                        height="100%"
                        style={{
                            aspectRatio: "1.77",
                        }}
                    />
                )}
        </>
    );

    const renderView = () => (
        <>
            {showFullScreen && (
                <AppContentModal
                    show={showFullScreen}
                    handleClose={() => setShowFullScreen(false)}
                    keyboard
                >
                    <div className="video-gallery-full-window">
                        <AppButton
                            className="close-button"
                            variant="secondary"
                            onClick={() => setShowFullScreen(false)}
                        >
                            <i className="fak fa-times-light" />
                        </AppButton>

                        {renderStreamPlayer()}
                    </div>
                </AppContentModal>
            )}

            <div
                className={`video-item ${className} ${
                    !isPopUp
                        ? "col-12 col-xs-6 col-sm-6 col-lg-4 col-xl-3"
                        : "popup"
                }`}
                onMouseOver={() => {
                    if (isPopUp) {
                        return;
                    }
                    setIsHoveringId(id);
                }}
                onMouseLeave={() => {
                    if (isPopUp) {
                        return;
                    }
                    setIsHoveringId(0);
                }}
            >
                <AppCard className="video-item--card p-0" id={String(id)}>
                    <div className="banner">
                        {id !== isHoveringId && !isPopUp ? (
                            <>
                                <div className="banner--content" style={style}>
                                    {isTop && (
                                        <div className="top-ribbon">
                                            <div className="content">
                                                <i className="fak fa-fire"></i>
                                                <span>
                                                    {t("video.list:badge.top")}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {renderCategoryTag()}
                            </>
                        ) : (
                            <div className="banner--content">
                                {!showFullScreen ? (
                                    <div
                                        onClick={() => {
                                            playVideo();
                                        }}
                                    >
                                        {renderStreamPlayer()}
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {isPopUp && (
                                    <AppButton
                                        variant="secondary"
                                        className="closeBtn"
                                        onClick={() => setIsPopUp(false)}
                                    >
                                        <i className="fak fa-times-light" />
                                    </AppButton>
                                )}

                                {getUrl() &&
                                    !showFullScreen &&
                                    streamType ===
                                        STREAM_TYPE.STREAMTYPE_FILE && (
                                        <a
                                            onClick={toggleMute}
                                            className="btn btn-primary muteBtn"
                                        >
                                            <i
                                                className={`${
                                                    isMuted
                                                        ? "fak fa-es-mute-volume"
                                                        : "fak fa-volume-on"
                                                }`}
                                            ></i>
                                        </a>
                                    )}
                            </div>
                        )}
                    </div>

                    <div
                        className="context"
                        onClick={() => {
                            if (isPopUp) return;
                            setIsPopUp(true);
                        }}
                    >
                        <div className="context--inner">
                            <div className="header mb-2">
                                {isPopUp && renderCategoryTag()}

                                <div className="info">
                                    <span className="view">
                                        {totalViews}{" "}
                                        {t("video.list:badge.views")}
                                    </span>

                                    {createdAt && (
                                        <span className="time">
                                            <span className="dot">●</span>{" "}
                                            {dateText(createdAt)}
                                        </span>
                                    )}
                                </div>

                                <div className="actions">
                                    {(id === isHoveringId || isPopUp) && (
                                        <div
                                            className="playBtn"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                playVideo();
                                            }}
                                        >
                                            <i className="fak fa-live"></i>
                                        </div>
                                    )}

                                    {id === isHoveringId && !isPopUp && (
                                        <AppButton
                                            className="foldBtn"
                                            variant="secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsPopUp(true);
                                            }}
                                        >
                                            <i className="fak fa-chevron-right"></i>
                                        </AppButton>
                                    )}

                                    {!isListView && (
                                        <AppButton
                                            className="shareBtn"
                                            variant="secondary"
                                            onClick={() =>
                                                navigate(
                                                    `/video-gallery?id=${id}`
                                                )
                                            }
                                        >
                                            <i className="fak fa-share-alt"></i>
                                        </AppButton>
                                    )}
                                </div>
                            </div>

                            <div className="title">
                                <AppTextHoverTooltip text={title}>
                                    <h3>{title}</h3>
                                </AppTextHoverTooltip>
                            </div>

                            {isPopUp && (
                                <>
                                    <div className="desc">
                                        <p
                                            className="text"
                                            dangerouslySetInnerHTML={
                                                !showMoreDesc
                                                    ? {
                                                          __html: `${description
                                                              ?.replace(
                                                                  /(<([^>]+)>)/gi,
                                                                  ""
                                                              )
                                                              .slice(
                                                                  0,
                                                                  ShowMoreTextCharLength
                                                              )}${
                                                              description.length >
                                                              ShowMoreTextCharLength
                                                                  ? "..."
                                                                  : ""
                                                          }`,
                                                      }
                                                    : {
                                                          __html: description,
                                                      }
                                            }
                                        />

                                        {description &&
                                            description.length >
                                                ShowMoreTextCharLength && (
                                                <Link
                                                    to="#"
                                                    onClick={() => {
                                                        setShowMoreDesc(
                                                            !showMoreDesc
                                                        );
                                                    }}
                                                    className="showMore"
                                                >
                                                    {showMoreDesc ? (
                                                        <>
                                                            -{" "}
                                                            {t(
                                                                "video.list.showLess"
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            +{" "}
                                                            {t(
                                                                "video.list.showMore"
                                                            )}
                                                        </>
                                                    )}
                                                </Link>
                                            )}
                                    </div>

                                    {getVideoTags().length > 0 && (
                                        <div className="tags">
                                            <div className="title mb-2">
                                                <h4>
                                                    <i className="fak fa-tags"></i>
                                                    <span>
                                                        {t(
                                                            "video.list:label.tags"
                                                        )}
                                                    </span>
                                                </h4>
                                            </div>

                                            <div className="content row m-0 p-0">
                                                {getVideoTags().map((tag) => (
                                                    <div
                                                        className="item col-auto px-0 mb-2"
                                                        key={tag.name}
                                                    >
                                                        <AppTextHoverTooltip
                                                            text={
                                                                tag.name as string
                                                            }
                                                            placement={"top"}
                                                        >
                                                            <span>
                                                                {tag.name}
                                                            </span>
                                                        </AppTextHoverTooltip>
                                                    </div>
                                                ))}

                                                {videoGalleryTags.length >
                                                    AppUserListDisplayLength && (
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            setShowMore(
                                                                !showMore
                                                            );
                                                        }}
                                                        className="showMore pl-2"
                                                    >
                                                        {showMore ? (
                                                            <>
                                                                -{" "}
                                                                {t(
                                                                    "video.list.showLess"
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                +{" "}
                                                                {t(
                                                                    "video.list.showMore"
                                                                )}
                                                            </>
                                                        )}
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className="users mb-2">
                                        <AppUsersList
                                            users={[
                                                ...(speakers as User[]),
                                                ...(moderators as User[]),
                                            ]}
                                            hasTitle
                                            title="video.list:section.title.speakersAndModerators"
                                            withShowMore={false}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        {isPopUp && (
                            <br
                                className="video-card-border"
                                style={{
                                    backgroundColor:
                                        videoGalleryCategory?.color,
                                }}
                            ></br>
                        )}
                    </div>
                </AppCard>
            </div>
        </>
    );

    return isPopUp ? (
        <AppContentModal
            show={isPopUp}
            handleClose={() => setIsPopUp(false)}
            keyboard
            className="video-detail-popup"
        >
            {renderView()}
        </AppContentModal>
    ) : (
        renderView()
    );
};
