import { CONSTANTS } from "../../config";
import { API_HOST } from "../config";
import { Client, Container, FileTypeInfo } from "../models";

const {
    Container: { STORAGE },
    Upload: { FILETYPE },
} = CONSTANTS;

export function useAssetHelper() {
    const buildPath = (
        fileInfo: FileTypeInfo,
        fileResource?: Client | Container,
        fileName: string | null = null
    ): string => {
        if (!fileResource) {
            return "";
        }

        const { path, key } = fileInfo;
        const { id, storage, bucketEndpoint, bucketName, bucketRegion } =
            fileResource;

        let basePath = `${API_HOST}/uploads/container_${id}`;

        if (
            fileResource instanceof Client &&
            (key === FILETYPE.FILETYPE_USER_PROFILE ||
                key === FILETYPE.FILETYPE_CONTAINER_POSTER ||
                key === FILETYPE.FILETYPE_CONTAINER_LOGO)
        ) {
            basePath = `${API_HOST}/uploads/client_${id}`;
        }

        if (storage === STORAGE.STORAGE_S3) {
            if (bucketEndpoint) {
                basePath = `${bucketEndpoint}/${bucketName}`;
            } else {
                basePath = `https://${bucketName}.s3.${bucketRegion}.amazonaws.com`;
            }
        }

        if (path) {
            basePath = `${basePath}/${path}`;
        }

        if (fileName) {
            return `${basePath}/${fileName}`;
        }

        return basePath;
    };

    return {
        buildPath,
    };
}
