import React, { FC, useState } from "react";
import { useLocation } from "@reach/router";

import {
    AppCard,
    AppTextHoverTooltip,
    AppButton,
    AppShowMoreButton,
    AppContentModal,
} from "..";
import { Client, CommonModelCardProps, Container } from "../../models";
import {
    ContainerPosterFileInfo,
    ContainerLogoFileInfo,
    ShowMoreTextCharLength,
} from "../../../config";
import {
    useBuildAssetPath,
    useRedirectHandler,
    useTranslation,
} from "../../hooks";
import { resolveImageWithStyle } from "../../utils";
import placeHolderImage from "../../assets/images/placeholders/img-thumb.svg";

import "./assets/scss/style.scss";

export interface ContainerCardProps extends CommonModelCardProps<Container> {
    id?: string;
}

export const ContainerCard: FC<ContainerCardProps> = ({
    item,
    className = "",
    // isListView = false,
    id,
}): JSX.Element => {
    const {
        name,
        description,
        imageName = "",
        logoImageName = "",
        containerCategory,
        client,
        domain,
    } = item;

    // hooks
    const { t } = useTranslation();
    const { openRegisterTab } = useRedirectHandler();

    // query params
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const userId = query.get("id");

    // image
    const basePath = useBuildAssetPath(
        ContainerPosterFileInfo,
        client as Client
    );
    const style = resolveImageWithStyle(basePath, imageName, placeHolderImage);

    const containerLogoPath = useBuildAssetPath(ContainerLogoFileInfo);
    const containerLogoStyle = resolveImageWithStyle(
        containerLogoPath,
        logoImageName,
        placeHolderImage
    );

    const [showMore, setShowMore] = useState<boolean>(false);

    return (
        <div
            className={`container-item ${className} col-12 col-sm-6 col-lg-4 col-xl-3`}
        >
            <AppContentModal
                show={showMore}
                handleClose={setShowMore}
                icon={"description"}
                title={`container.list:section.title.description`}
                size="lg"
                keyboard
            >
                <p className="text">{description}</p>
            </AppContentModal>

            <AppCard className="container-item--card p-0" id={id}>
                <div className="banner">
                    <div className="banner--content" style={style}>
                        {logoImageName && (
                            <div className="presenter">
                                <i style={containerLogoStyle} />
                            </div>
                        )}
                    </div>

                    {containerCategory && (
                        <div className="category">
                            <div
                                className="content"
                                style={{
                                    "--container-category-background-color":
                                        containerCategory?.color,
                                    backgroundColor: containerCategory?.color,
                                }}
                            >
                                <span
                                    style={{
                                        color: containerCategory?.textColor,
                                    }}
                                >
                                    {containerCategory?.name}
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                <div className="context">
                    <div className="context--inner">
                        <div className="header mb-2">
                            <div className="title">
                                <AppTextHoverTooltip text={name}>
                                    <h3>{name}</h3>
                                </AppTextHoverTooltip>
                            </div>
                        </div>

                        <div className="desc">
                            <p className="text">{description}</p>
                        </div>

                        <div className="actions">
                            {description &&
                                description.length > ShowMoreTextCharLength && (
                                    <AppShowMoreButton
                                        onClick={() => setShowMore(true)}
                                    />
                                )}

                            <div className="registerBtn">
                                <AppButton
                                    className="registerBtn"
                                    variant="primary"
                                    onClick={() => {
                                        const routePath = userId
                                            ? `/attendee/${userId}/show`
                                            : undefined;

                                        openRegisterTab(domain, routePath);
                                    }}
                                >
                                    {t("public.register.form:registerButton")}
                                </AppButton>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="category-border"
                    style={{ backgroundColor: containerCategory?.color }}
                />
            </AppCard>
        </div>
    );
};
