import React, { FC } from "react";

import "./assets/scss/style.scss";

export interface AppVideoPlayerType {
    url: string;
    autoPlay?: boolean;
    muted?: boolean;
    controls?: boolean;
    onMouseOver?: (
        event: React.MouseEvent<HTMLVideoElement, MouseEvent>
    ) => void;
    onMouseOut?: (
        event: React.MouseEvent<HTMLVideoElement, MouseEvent>
    ) => void;
    id?: string;
}

export const AppVideoPlayer: FC<AppVideoPlayerType> = ({
    url,
    autoPlay = false,
    muted = false,
    controls = true,
    id = "app-video-player",
}): JSX.Element => {
    return (
        <div className="video-container">
            <div className="video-frame">
                <video
                    id={id}
                    className="video"
                    autoPlay={autoPlay}
                    muted={muted}
                    controls={controls}
                >
                    <source src={url} type="video/mp4" />
                </video>
            </div>
        </div>
    );
};
