import React, { FC, useState } from "react";
import {
    AppContainer,
    AppSectionHeader,
    AppShowMoreButton,
    CourseCard,
} from "..";
import { SectionLimit } from "../../../config";
import { useTranslation } from "../../hooks";
import { ELCourse } from "../../models";
import "./assets/scss/style.scss";

export interface CourseOverviewProps {
    items: ELCourse[];
    className?: string;
    hasTitle?: boolean;
}

export const CourseOverview: FC<CourseOverviewProps> = ({
    items,
    className = "",
    hasTitle = false,
}): JSX.Element => {
    // hooks
    const { t } = useTranslation();

    const domainList: string[] = [];

    const [isDisplay, setIsDisplay] = useState<number>(0);

    return (
        <div className={`course-overview ${className}`} id="courseOverview">
            <AppContainer>
                {hasTitle && (
                    <AppSectionHeader
                        title={t("course.section:label.title")}
                        subTitle={t("course.section:label.description")}
                    />
                )}

                <div className="course-overview--items">
                    <div className="row">
                        {items.slice(0, SectionLimit.COURSE).map((item) => {
                            let isNew = false;
                            if (
                                item?.container?.domain &&
                                !domainList.includes(item.container.domain)
                            ) {
                                isNew = true;
                                domainList.push(item.container.domain);
                            }
                            return (
                                <CourseCard
                                    key={item.id}
                                    item={item}
                                    isNew={isNew}
                                    isDisplay={isDisplay === item.id}
                                    setIsDisplay={(selectedId) =>
                                        setIsDisplay((prev) =>
                                            prev !== selectedId ? selectedId : 0
                                        )
                                    }
                                />
                            );
                        })}
                    </div>
                </div>

                {items.length >= SectionLimit.COURSE && (
                    <AppShowMoreButton
                        className="showMoreItems"
                        path="/course"
                    />
                )}
            </AppContainer>
        </div>
    );
};
