import React, { useEffect } from "react";
import "./assets/scss/style.scss";

interface AppQuMuPlayerProps {
    url: string;
    className?: string;
}

export const AppQuMuPlayer: React.FC<AppQuMuPlayerProps> = ({
    url,
    className,
}): JSX.Element => {
    useEffect(() => {
        const quMuIframe = document.createElement("iframe");
        quMuIframe.allow = "microphone; camera; fullscreen;";
        quMuIframe.name = "player-iframe-name";
        quMuIframe.src = url;

        if (className) {
            quMuIframe.className = className;
        }

        const script = document.createElement("script");
        quMuIframe.appendChild(script);
        const container = document.getElementById("quMuContainerId");

        if (container) {
            container.appendChild(quMuIframe);
        } else {
            // eslint-disable-next-line no-console
            console.warn("can not find the element id");
        }
    }, []);

    return <div id="quMuContainerId"></div>;
};
