import { Canceler } from "axios";

import { SimpleObject } from "../models";
import { isString } from "./dataType";
import { randomAlphaNumeric } from "./random";

export const generateKeyHeader = (saltLen = 6): string => {
    const { location } = window;
    const { host } = location;
    const hostBase64 = btoa(host);
    const prefixSalt = randomAlphaNumeric(saltLen);
    const suffixSalt = randomAlphaNumeric(saltLen);

    return `${prefixSalt}${hostBase64}${suffixSalt}`;
};

export const checkAndParseResponse = (
    data: string | SimpleObject<any>
): SimpleObject<any> => {
    let parseData: SimpleObject<any> = {};
    if (isString(data)) {
        try {
            parseData = JSON.parse(data as string);
        } catch (e) {
            throw new Error("Invalid response");
        }
    } else {
        parseData = data;
    }

    return parseData;
};

export const cancelAllPrevRequest = (cancelTokenSources: Canceler[]) => {
    while (cancelTokenSources.length > 0) {
        const c = cancelTokenSources.shift();
        if (c) {
            c();
        }
    }
};
