import { useLocation } from "@reach/router";
import { registerPath } from "../utils";

export function useRedirectHandler() {
    const { protocol } = useLocation();

    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    const openRegisterTab = (domain: string, routePath?: string) => {
        const path = routePath || registerPath;

        const url = `${protocol}//${domain}${path}`;

        openInNewTab(url);
    };

    return { openInNewTab, openRegisterTab };
}
