import React, { FC, useEffect } from "react";
// eslint-disable-next-line import/named
import { RouteComponentProps } from "@reach/router";
import { AppLoader } from "../../components";
import { useNavigator } from "../../hooks";

export const ReloadingPage: FC<RouteComponentProps> = (
    props: any
): JSX.Element => {
    const nav = useNavigator(props.navigate);

    useEffect(() => {
        if (
            props.location &&
            props.location.state &&
            props.location?.state?.url
        )
            nav(props.location?.state?.url, {
                state: props.location.state,
            }).then();
    });
    return <AppLoader containerClassName="centerLoader" />;
};
