import React, { PropsWithChildren, useEffect } from "react";
import { DesignConfigurationFileInfo } from "../../config";
import { PublicAPI } from "../apis";
import { AppLoader } from "../components";
import { i18n, USER_LOCALE } from "../config";
import { useAssetHelper, useDomHelper, useExhibitors } from "../hooks";
import {
    Client,
    Configuration,
    Container,
    DesignConfiguration,
    Language,
} from "../models";
import { errorToast, parseConfiguration, parseDesign } from "../utils";

interface GlobalState {
    status: "LOADED" | "LOADING" | "ERROR";
    client?: Client;
    mainContainer?: Container;
    designConfig: DesignConfiguration;
    configuration: Configuration;
    activeLanguages: Language[];
}

const Global = React.createContext<GlobalState | null>(null);

export const useGlobalData = (): GlobalState => {
    const context = React.useContext(Global);
    if (context === null) {
        throw new Error("useGlobalData must be used within a GlobalProvider");
    }

    return context;
};

export const GlobalProvider: React.FC<PropsWithChildren> = (props) => {
    const [state, setState] = React.useState<GlobalState>({
        status: "LOADING",
        designConfig: new DesignConfiguration(),
        configuration: new Configuration(),
        activeLanguages: [],
    });

    const { setStyle, setFavicon } = useDomHelper();
    const { getExhibitors } = useExhibitors(); // fetch all exhibitors & store to recoil
    const { buildPath } = useAssetHelper();

    const generateDynamicStyle = (clientId: number) => {
        PublicAPI.getStyle(clientId).then(({ data }) => {
            setStyle(data);
        });
    };

    const handleFavicon = (
        mainContainer: Container,
        designConfiguration: DesignConfiguration
    ) => {
        const { genImageFavicon } = designConfiguration;

        const favicon = buildPath(
            DesignConfigurationFileInfo,
            mainContainer,
            genImageFavicon
        );

        if (genImageFavicon !== "") {
            setFavicon(favicon);
        }
    };

    const fetchMyClient = () => {
        setState((prevState) => ({
            ...prevState,
            status: "LOADING",
        }));

        PublicAPI.getMyClient<Client>().then(
            async ({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                    setState((prevState) => ({
                        ...prevState,
                        status: "ERROR",
                    }));
                } else if (response) {
                    if (!response.items.length) {
                        setState((prevState) => ({
                            ...prevState,
                            status: "ERROR",
                        }));
                    }

                    const [payload] = response.items;

                    // generate dynamic css style
                    generateDynamicStyle(payload.id);

                    // fetch main container config & store to global variable
                    const mainContainer = payload.containers.find(
                        (item) => item.isMainContainer
                    );

                    // default language of mainContainer
                    const defaultLanguage = mainContainer?.languages?.find(
                        (item) => item.isDefault
                    );

                    // initialize dynamic translations
                    const locale =
                        localStorage.getItem(USER_LOCALE) ||
                        defaultLanguage?.locale;
                    i18n.init(payload.id, locale);

                    // parsed design config & store to global variable
                    const designConfiguration = parseDesign(mainContainer);

                    const configuration = parseConfiguration(mainContainer);

                    // set dynamic favicon
                    if (mainContainer) {
                        handleFavicon(mainContainer, designConfiguration);
                    }

                    PublicAPI.getAllLanguage<Language>().then(
                        ({ response: res, errorMessage: err }) => {
                            if (err) {
                                errorToast(err);
                                setState((prevState) => ({
                                    ...prevState,
                                    status: "ERROR",
                                }));
                            } else if (res) {
                                setState((prevState) => ({
                                    ...prevState,
                                    status: "LOADED",
                                    client: payload,
                                    mainContainer,
                                    designConfig: designConfiguration,
                                    configuration,
                                    activeLanguages: res.items,
                                }));
                            }
                        }
                    );
                }
            }
        );
    };

    useEffect(() => {
        fetchMyClient();
        getExhibitors();
    }, []);

    if (state.status === "LOADING") {
        return <AppLoader containerClassName="centerLoader" />;
    }

    return <Global.Provider value={state}>{props.children}</Global.Provider>;
};
