import { Link } from "@reach/router";
import React, { FC } from "react";

import { useTranslation } from "../../hooks";

import "./assets/scss/styles.scss";

export interface AppShowMoreButtonProps {
    path?: string;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const AppShowMoreButton: FC<AppShowMoreButtonProps> = ({
    path = "#",
    className = "",
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`showMore ${className}`}>
            <Link
                to={path}
                {...(path === "#"
                    ? {
                          onClick: (e) => {
                              if (onClick) {
                                  onClick(e);
                              }
                          },
                      }
                    : {})}
            >
                + {t("common:button.showMore")}
            </Link>
        </div>
    );
};
