import React, { FC, useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/named
import { RouteComponentProps, useLocation } from "@reach/router";
import { Canceler } from "axios";

import {
    AppBanner,
    AppLoader,
    ContainerCard,
    AppContainer,
    AppBlankDataHelper,
    AppSectionHeader,
} from "../../components";
import { PublicAPI } from "../../apis";
import { cancelAllPrevRequest, errorToast, itemsPerPage } from "../../utils";
import { Container } from "../../models";
import {
    useDetectInViewport,
    useRedirectHandler,
    useTranslation,
} from "../../hooks";

import "./assets/styles/styles.scss";

export const ContainerPage: FC<RouteComponentProps> = (): JSX.Element => {
    const { t } = useTranslation();
    const { openRegisterTab } = useRedirectHandler();

    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    // query params
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const userId = query.get("id");
    const userGroupIds =
        query
            .get("user-group")
            ?.split(",")
            ?.map((id) => Number(id)) || [];

    // state
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Container[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const isHasLoadMore = !loading && totalItems > data.length && hasMore;

    const fetchData = (
        page?: number,
        replaceData?: Container[],
        params = {}
    ) => {
        cancelAllPrevRequest(cancelTokenSourcesRef.current);
        setLoading(true);

        const activePage = page || currentPage;

        if (userGroupIds.length) {
            params = {
                "userGroups.id": userGroupIds,
            };
        }

        PublicAPI.getAllContainer<Container>(
            activePage,
            {
                itemsPerPage,
                ...params,
            },
            (c) => {
                cancelTokenSourcesRef.current.push(c);
            }
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    if (userGroupIds.length && response.items.length === 1) {
                        const [{ domain = "" }] = response.items;
                        if (domain)
                            openRegisterTab(domain, `/attendee/${userId}/show`);
                    }

                    const currentData = replaceData || data;

                    setCurrentPage(activePage + 1);
                    setData([...currentData, ...response.items]);
                    setTotalItems(response.totalItems);

                    if (response.items.length < itemsPerPage) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const lastRef = useDetectInViewport(isHasLoadMore, () => {
        fetchData();
    });

    return (
        <div className="container-container">
            <AppBanner />

            <AppContainer className="mt-4">
                <AppSectionHeader
                    title={t("container.section:label.title")}
                    subTitle={t("container.section:label.description")}
                />

                {!!(!loading && !data.length) && <AppBlankDataHelper />}

                <div className="container-container--items">
                    <div className="row">
                        {data.map((item) => (
                            <ContainerCard
                                key={item.id}
                                item={item}
                                isListView
                            />
                        ))}
                    </div>
                    {isHasLoadMore && <div ref={lastRef} />}
                </div>

                {loading && (
                    <AppLoader
                        containerClassName={
                            currentPage !== 1
                                ? "load-more-loader"
                                : "centerLoader"
                        }
                    />
                )}
            </AppContainer>
        </div>
    );
};
