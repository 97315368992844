import React, { useEffect, useRef } from "react";
import ArcText from "arc-text";

interface AppBadgeArcTextProps {
    text: string;
    class: string;
    direction: number;
    arc: number;
}

export const AppBadgeArcText: React.FC<AppBadgeArcTextProps> = (props) => {
    const container = useRef<HTMLDivElement>(null);
    const textCurved = useRef<any>(null);
    const text = props.text || "";
    const direction = props.direction || 1;
    const arc = props.arc || 150;
    const clsName = props.class || "";

    const arcLetters = () => {
        if (container.current) {
            if (textCurved.current) {
                textCurved.current.destroy();
            }

            container.current.innerHTML = text;
            textCurved.current = new ArcText(container.current);
            textCurved.current.arc(arc);
            textCurved.current.direction(direction);
        }
    };

    useEffect(() => {
        arcLetters();
    }, []);

    return <div className={`${clsName}`} ref={container} />;
};
