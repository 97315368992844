import React, { FC, useEffect, useState } from "react";
// eslint-disable-next-line import/named
import { RouteComponentProps } from "@reach/router";

import {
    AppBanner,
    EventOverview,
    VideoOverview,
    AttendeeOverview,
    CourseOverview,
    ContainerOverview,
    AppLoader,
} from "../../components";
import { PublicAPI } from "../../apis";
import { errorToast } from "../../utils";
import { PublicData } from "../../models";

import "./assets/styles/styles.scss";

export const PublicPage: FC<RouteComponentProps> = (): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<PublicData>(new PublicData());

    const fetchData = () => {
        setLoading(true);

        PublicAPI.getAll<PublicData>()
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    setData(response);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="public-container">
            <AppBanner />

            {loading && <AppLoader />}

            {/* Event Section */}
            {data.conferences.length > 0 && (
                <EventOverview
                    hasTitle
                    className="py-4"
                    items={data.conferences}
                />
            )}

            {/* Video Section */}
            {data.videoGalleries.length > 0 && (
                <VideoOverview
                    hasTitle
                    className="py-4"
                    items={data.videoGalleries}
                />
            )}

            {/* Attendee Section */}
            {data.attendees.length > 0 && (
                <AttendeeOverview
                    hasTitle
                    className="py-4"
                    items={data.attendees}
                />
            )}

            {/* Course Section */}
            {data.courses.length > 0 && (
                <CourseOverview
                    hasTitle
                    className="py-4"
                    items={data.courses}
                />
            )}

            {/* Container Section */}
            {data.containers.length > 0 && (
                <ContainerOverview
                    hasTitle
                    className="py-4"
                    items={data.containers}
                />
            )}
        </div>
    );
};
