import React, { FC } from "react";
import "./assets/scss/styles.scss";

export interface AppSectionHeaderProps {
    className?: string;
    title?: string;
    subTitle?: string;
}

export const AppSectionHeader: FC<AppSectionHeaderProps> = ({
    className = "",
    title,
    subTitle,
}): JSX.Element => {
    return (
        <div className={`section-header ${className}`}>
            <div className="section-header--content">
                <h2 className="mb-1">{title}</h2>
                <p className="mb-0">{subTitle} </p>
            </div>
        </div>
    );
};
