import { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { Canceler } from "axios";

import { cancelAllPrevRequest } from "../utils";
import { PublicAPI } from "../apis";
import { Exhibitor } from "../models";
import { appExhibitors } from "../atoms";

type UseExhibitorsType = {
    loading: boolean;
    getExhibitors: () => Promise<Exhibitor[]>;
};

export function useExhibitors(): UseExhibitorsType {
    const [loading, setLoading] = useState<boolean>(false);
    const [exhibitors, setExhibitors] = useRecoilState(appExhibitors);
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    const getExhibitors = async (): Promise<Exhibitor[]> => {
        cancelAllPrevRequest(cancelTokenSourcesRef.current);

        setLoading(true);
        return new Promise((resolve) => {
            if (exhibitors !== null) {
                resolve(exhibitors);
                setLoading(false);
            } else {
                PublicAPI.getAllExhibitorList<Exhibitor>({}, (c) => {
                    cancelTokenSourcesRef.current.push(c);
                })
                    .then(({ response }) => {
                        if (response !== null && response?.items) {
                            resolve(response.items);
                            setExhibitors(response?.items);
                        } else {
                            resolve([]);
                        }
                    })
                    .catch(() => {
                        resolve([]);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        });
    };

    return {
        getExhibitors,
        loading,
    };
}
