import React, { FC } from "react";

import { User } from "../../models";
import { resolveImageWithStyle } from "../../utils";
import { UserProfileFileInfo } from "../../../config";
import { useBuildAssetPath, useUserJobCompany } from "../../hooks";

import placeHolderImage from "../../assets/images/placeholders/user-thumb.png";

import "./assets/scss/style.scss";
interface AppUserItemProps {
    item: User;
}

export const AppUserItem: FC<AppUserItemProps> = ({ item }) => {
    const {
        salutation,
        firstName,
        lastName,
        imageName = "",
        jobTitle,
        company,
        country,
        client,
    } = item;

    const { displayJobCompany } = useUserJobCompany();

    // image
    const basePath = useBuildAssetPath(UserProfileFileInfo, client);
    const style = resolveImageWithStyle(basePath, imageName, placeHolderImage);

    return (
        <div className={"user-item"}>
            <div className="user-item--inner">
                <a>
                    <div className="profile">
                        <i style={style}></i>
                    </div>
                    <div className="details">
                        <h4 className="name w-100 d-flex align-items-center">
                            <p>
                                {salutation} {firstName} {lastName}
                            </p>
                            <div className="user-flag f16 pl-2">
                                <div className={`flag ${country}`}></div>
                            </div>
                        </h4>
                        <span className="job-company">
                            {displayJobCompany(jobTitle, company)}
                        </span>
                    </div>
                </a>
            </div>
        </div>
    );
};
