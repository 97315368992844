import React, { CSSProperties, FC } from "react";
// eslint-disable-next-line import/named
import { Variant } from "react-bootstrap/types";

import "./assets/scss/style.scss";

export interface AppLoaderProps {
    containerClassName?: string;
    containerStyle?: CSSProperties;
    spinnerClassName?: string;
    spinnerContainerStyle?: CSSProperties;
    spinnerAnimation?: "border" | "grow";
    spinnerSize?: "sm";
    spinnerVariant?: Variant;
    coverContainer?: boolean;
}

export const AppLoader: FC<AppLoaderProps> = ({
    containerClassName = "",
    containerStyle,
    coverContainer = true,
}) => (
    <div
        className={
            coverContainer
                ? `loaderContainer ${containerClassName}`
                : containerClassName
        }
        style={containerStyle}
    >
        <div className="default-spinner">
            <svg
                width="40"
                height="40"
                viewBox="0 0 57 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="pb-3"
            >
                <path
                    d="M15.3555 14.8701C16.2925 11.1012 19.6756 8.45544 23.5581 8.45544H48.3582C53.9164 8.45544 57.9617 13.7298 56.5224 19.1002L48.5449 48.8672C48.0497 50.7152 46.3754 52.0001 44.4628 52.0001H16.9366C11.4419 52.0001 7.40788 46.8382 8.73396 41.5042L15.3555 14.8701Z"
                    fill="#2CDA9D"
                />
                <path
                    d="M8.0687 6.41467C9.0075 2.64574 12.3972 0 16.2871 0H41.9819C47.5508 0 51.6039 5.27432 50.1618 10.6447L43.0102 37.2788C42.0178 40.9748 38.6629 43.5447 34.8303 43.5447H4.23644C1.48383 43.5447 -0.537083 40.9637 0.127235 38.2967L8.0687 6.41467Z"
                    fill="#0052CC"
                    fillOpacity="0.89"
                />
            </svg>
            <div className="dot-pulse"></div>
        </div>
    </div>
);
