import { DesignConfigurationFileInfo } from "../../config";
import { useGlobalData } from "../contexts";
import { parseDesign } from "../utils";
import { useBuildAssetPath } from "./useBuildAssetPath";

export enum DefaultImagePlaceHolderEnum {
    Container = 1,
    Event,
    Session,
    Document,
    Exhibitor,
    ExhibitorProduct,
    Attendee,
    ElearningCourse,
    ElearningTask,
    VideoGallery,
    DICOM,
    CommonCategory,
}

type DefaultImgPlaceholderType = {
    placeHolderImage: string;
};

export function useDefaultImgPlaceholder(
    type: DefaultImagePlaceHolderEnum,
    placeHolder?: string
): DefaultImgPlaceholderType {
    const { container } = useGlobalData();
    const design = parseDesign(container);

    const designConfigBasePath = useBuildAssetPath(DesignConfigurationFileInfo);

    let configKey = "";
    placeHolder = placeHolder || "";

    switch (type) {
        case DefaultImagePlaceHolderEnum.Container:
            configKey = design.defImageContainer;
            break;
        case DefaultImagePlaceHolderEnum.Event:
            configKey = design.defImageEvent;
            break;
        case DefaultImagePlaceHolderEnum.Session:
            configKey = design.defImageSession;
            break;
        case DefaultImagePlaceHolderEnum.Document:
            configKey = design.defImageDocument;
            break;
        case DefaultImagePlaceHolderEnum.Exhibitor:
            configKey = design.defImageExhibitor;
            break;
        case DefaultImagePlaceHolderEnum.ExhibitorProduct:
            configKey = design.defImageExhibitorProduct;
            break;
        case DefaultImagePlaceHolderEnum.Attendee:
            configKey = design.defImageAttendee;
            break;
        case DefaultImagePlaceHolderEnum.ElearningCourse:
            configKey = design.defImageElCourse;
            break;
        case DefaultImagePlaceHolderEnum.ElearningTask:
            configKey = design.defImageElTask;
            break;
        case DefaultImagePlaceHolderEnum.VideoGallery:
            configKey = design.defImageVideoGallery;
            break;
        case DefaultImagePlaceHolderEnum.DICOM:
            configKey = design.defImageDicom;
            break;
        case DefaultImagePlaceHolderEnum.CommonCategory:
            configKey = design.defImageNewsfeed;
            break;

        default:
            break;
    }

    return {
        placeHolderImage: configKey
            ? `${designConfigBasePath}/${configKey}`
            : placeHolder,
    };
}
