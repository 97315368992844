import React, { useCallback, useEffect, useRef } from "react";

export const useDetectInViewport = (
    isTracking: boolean,
    callback: () => any | Promise<any>
): React.MutableRefObject<any> => {
    const loader = useRef(null);

    const handleObserver = useCallback(
        (entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                callback();
            }
        },
        [isTracking]
    );

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0,
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, [handleObserver]);

    return loader;
};
