import React, { FC } from "react";
import { Link } from "@reach/router";

import { Container, Exhibitor } from "../../models";
import { useBuildAssetPath } from "../../hooks";
import { ExhibitorLogoPosterFileInfo } from "../../../config";
import { resolveImage } from "../../utils";
import placeHolderImage from "../../assets/images/placeholders/img-thumb.svg";

import "./assets/scss/style.scss";
interface AppSponsorType {
    item: Exhibitor;
    container: Container;
}

export const AppSponsor: FC<AppSponsorType> = ({ item, container }) => {
    const { logoImageName } = item;

    // image
    const basePath = useBuildAssetPath(ExhibitorLogoPosterFileInfo, container);
    const sponsorImage = resolveImage(
        basePath,
        logoImageName,
        placeHolderImage
    );

    return (
        <div className={"app-sponsors--item"}>
            <Link to="#">
                <img src={sponsorImage} />
            </Link>
        </div>
    );
};
