import React, { FC, useState } from "react";

import { User } from "../../models";
import { AppUserItem } from "./AppUserItem";
import { useTranslation } from "../../hooks";
import { AppShowUserListPopup } from "../AppShowUserListPopup";
import { AppShowMoreButton } from "../AppShowMoreButton";
import "./assets/scss/style.scss";
import { AppUserListDisplayLength } from "../../../config";

interface AppUsersListProps {
    hasTitle?: boolean;
    users: User[];
    title?: string;
    withShowMore?: boolean;
}

export const AppUsersList: FC<AppUsersListProps> = ({
    hasTitle = false,
    users,
    title = "",
    withShowMore = true,
}) => {
    const { t } = useTranslation();

    const [showMore, setShowMore] = useState<boolean>(false);

    const getUniqueUsers = () => {
        const uniqueIds: number[] = [];
        return users.filter((user) => {
            const data = !uniqueIds.includes(user.id);
            uniqueIds.push(user.id);
            return data;
        });
    };

    const getUsers = () => {
        return withShowMore
            ? getUniqueUsers().slice(0, AppUserListDisplayLength)
            : getUniqueUsers();
    };

    if (!getUniqueUsers().length) {
        return <></>;
    }

    return (
        <div
            className={`users-list ${
                !withShowMore ? "scrollable" : "withShowMore"
            }`}
        >
            <AppShowUserListPopup
                show={showMore}
                handleClose={setShowMore}
                users={getUniqueUsers()}
                title={title}
            />

            <div className="users-list--inner">
                {hasTitle && (
                    <div className="title mb-3">
                        <h4>
                            <i className="fak fa-speakers"></i>
                            <span>{t(title)}</span>
                        </h4>
                    </div>
                )}

                <div className="content pl-3">
                    {getUsers().map((user) => (
                        <AppUserItem key={user.id} item={user} />
                    ))}
                </div>

                {withShowMore &&
                    getUniqueUsers().length > AppUserListDisplayLength && (
                        <AppShowMoreButton
                            className="mt-2"
                            onClick={() => {
                                setShowMore(true);
                            }}
                        />
                    )}
            </div>
        </div>
    );
};
