import React from "react";
import { Redirect, Router } from "@reach/router";
import { appRouters } from "./bootstrap";
import { AppLayout } from "./layouts/AppLayout";
import "./assets/scss/bootstrap.scss";
import { init } from "./config/date-fns";
import { useTranslation } from "./hooks";

const App = (): JSX.Element => {
    const { t } = useTranslation();

    // init date translation key after status is loaded
    init(t);

    return (
        <AppLayout>
            <Router primary={false}>
                {appRouters.map(({ RouterPlug, key, path }) => {
                    return <RouterPlug key={key} path={path} />;
                })}
                <Redirect noThrow from="*" to={"/"} />
            </Router>
        </AppLayout>
    );
};

export default App;
