import React, { FC } from "react";
import { Form, InputGroup } from "react-bootstrap";
import "./assets/scss/style.scss";

export interface AppSearchBarProps {
    onChange?: (event) => void;
    iconLeft?: boolean;
    placeHolder?: string;
}

export const AppSearchBar: FC<AppSearchBarProps> = ({
    onChange = () => null,
    iconLeft = false,
    placeHolder = "Search...",
}): JSX.Element => {
    return (
        <Form className={"header-search"}>
            <Form.Row className={"justify-content-end m-auto"}>
                <InputGroup
                    className={`header-search--content pl-1 ${
                        iconLeft ? "icon-left" : ""
                    }`}
                >
                    <Form.Control
                        placeholder={placeHolder}
                        type={"search"}
                        onChange={onChange}
                    ></Form.Control>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="searchIcon fak fa-search-test" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                </InputGroup>
            </Form.Row>
        </Form>
    );
};
