import React, { FC } from "react";
import {
    AppContainer,
    AppSectionHeader,
    AppShowMoreButton,
    ContainerCard,
} from "..";
import { SectionLimit } from "../../../config";
import { useTranslation } from "../../hooks";
import { Container } from "../../models";
import "./assets/scss/style.scss";

export interface ContainerOverviewProps {
    items: Container[];
    className?: string;
    hasTitle?: boolean;
}

export const ContainerOverview: FC<ContainerOverviewProps> = ({
    items,
    className = "",
    hasTitle = false,
}): JSX.Element => {
    // hooks
    const { t } = useTranslation();

    return (
        <div
            className={`container-overview ${className}`}
            id="containerOverview"
        >
            <AppContainer>
                {hasTitle && (
                    <AppSectionHeader
                        title={t("container.section:label.title")}
                        subTitle={t("container.section:label.description")}
                    />
                )}

                <div className="container-overview--items">
                    <div className="row">
                        {items.slice(0, SectionLimit.CONTAINER).map((item) => (
                            <ContainerCard key={item.id} item={item} />
                        ))}
                    </div>
                </div>

                {items.length >= SectionLimit.CONTAINER && (
                    <AppShowMoreButton
                        className="showMoreItems"
                        path="/container"
                    />
                )}
            </AppContainer>
        </div>
    );
};
