import React, { FC } from "react";
import { Row } from "react-bootstrap";

import { User } from "../../models";
import { useTranslation } from "../../hooks";
import { AppContentModal } from "../AppContentModal";
import { AppUserItem } from "../AppUsersList/AppUserItem";

import "./assets/scss/style.scss";
import { AppSearchBar } from "../AppSearchBar";

export interface AppShowUserListPopupProps {
    show: boolean;
    handleClose: (show: boolean) => void;
    users: User[];
    title?: string;
    icon?: string;
}

export const AppShowUserListPopup: FC<AppShowUserListPopupProps> = ({
    show,
    handleClose,
    users,
    title = "",
    icon = "speakers",
}): JSX.Element => {
    const { t } = useTranslation();

    const [search, setSearch] = React.useState<string>("");

    const defaultTitle = `${t("session.form:label.speakers")} & ${t(
        "session.form:label.moderators"
    )}`;

    return (
        <AppContentModal
            show={show}
            handleClose={handleClose}
            icon={icon}
            title={title || defaultTitle}
            className="user-list-modal"
        >
            <AppSearchBar
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
            />

            <div className="content mt-3">
                <Row className="m-0 content--inner">
                    {users
                        .filter(
                            (e) =>
                                (e.firstName &&
                                    e.firstName
                                        .toLocaleLowerCase()
                                        .includes(
                                            search.toLocaleLowerCase()
                                        )) ||
                                (e.lastName &&
                                    e.lastName
                                        .toLocaleLowerCase()
                                        .includes(search.toLocaleLowerCase()))
                        )
                        .map((user: User) => {
                            return (
                                <div
                                    className="content--inner--item col-12 px-0"
                                    key={user.id}
                                >
                                    <Row className="m-0">
                                        <div className="detail">
                                            <AppUserItem item={user} />
                                        </div>
                                    </Row>
                                </div>
                            );
                        })}
                </Row>
            </div>
        </AppContentModal>
    );
};
