export const isString = (value): value is string => {
    return typeof value === "string" || value instanceof String;
};

export const isNumber = (value): value is number => {
    return typeof value === "number" || value instanceof Number;
};

export const isEllipsisActive = (element, text = "") => {
    if (element) {
        if (text.length) {
            return element.offsetWidth < text.length;
        }

        return element.offsetWidth < element.scrollWidth;
    }
    return false;
};
