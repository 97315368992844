import { useGlobalData } from "../contexts";
import { Client, Container, FileTypeInfo } from "../models";
import { useAssetHelper } from "./useAssetHelper";

export function useBuildAssetPath(
    fileInfo: FileTypeInfo,
    fileResource?: Client | Container,
    fileName: string | null = null
): string {
    const { client } = useGlobalData();
    const { buildPath } = useAssetHelper();

    return buildPath(fileInfo, fileResource || client, fileName);
}
