import React, { FC } from "react";

import "./assets/scss/style.scss";

import placeHolderImage from "../../assets/images/logo/vlogo.png";

// social icons
import twitterIcon from "./assets/images/TwitterIconX.svg";
import linkedinIcon from "./assets/images/LinkedInIcon.svg";
import Facebook from "./assets/images/FacebookIcon2.svg";
import { AppContainer } from "../AppContainer";
import { useTranslation } from "../../../AppModule/hooks";

export interface AppFooterProps {
    className?: string;
    hasTitle?: boolean;
}

export const AppFooter: FC<AppFooterProps> = ({
    className = "",
}): JSX.Element => {
    const { t } = useTranslation();
    // hooks

    // image

    return (
        <footer className={`footer ${className}`}>
            <AppContainer className="footer--container">
                <div className="content">
                    <div className="logo-container">
                        <img className="logo" src={placeHolderImage} />
                        <p className="about">
                            {t("PublicPage.Footer:label.docliftSlogan")}
                        </p>
                        <div className="item socials">
                            <div className="d-flex">
                                <div className="social-item p-1 mt-4">
                                    <a
                                        href="https://www.linkedin.com/company/89648498"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={linkedinIcon} />
                                    </a>
                                </div>
                                <div className="social-item p-1 mt-4">
                                    <a
                                        href="https://www.facebook.com/expertsharelive/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={Facebook} />
                                    </a>
                                </div>
                                <div className="social-item p-1 mt-4">
                                    <a
                                        href="https://twitter.com/expertsharelive"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={twitterIcon} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-col col5 mr-0 ml-auto">
                        <div className="footer-col--header">
                            <h4>{t("PublicPage.Footer:label.OurAdress")}</h4>
                        </div>
                        <div className="footer-col--content">
                            <div>
                                <p className="adress">
                                    {t(
                                        "PublicPage.Footer:adress.label.doclift"
                                    )}
                                </p>
                            </div>
                            <div>
                                <p className="adress">
                                    {t(
                                        "PublicPage.Footer:adress.label.ifzaproperties"
                                    )}
                                </p>
                            </div>
                            <div>
                                <p className="adress">
                                    {t(
                                        "PublicPage.Footer:adress.label.DubaiSiliconOasis"
                                    )}
                                </p>
                            </div>
                            <div>
                                <p className="adress">
                                    {t("PublicPage.Footer:adress.label.Dubai")}
                                </p>
                            </div>
                            <div>
                                <p className="adress">
                                    {t("PublicPage.Footer:adress.label.UAE")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="footer-col col4 mr-0 ml-auto">
                        <div className="footer-col--header">
                            <h4>{t("PublicPage.Footer:label.QuickLinks")}</h4>
                        </div>
                        <div className="footer-col--content">
                            <div className="item">
                                <a
                                    href="https://doclift.com/features/"
                                    rel="noreferrer"
                                >
                                    {t(
                                        "PublicPage.Footer:quickLinks.label.features"
                                    )}
                                </a>
                            </div>
                            <div className="item">
                                <a
                                    href="https://doclift.com/hcps-doclift-about-us/"
                                    rel="noreferrer"
                                >
                                    {t(
                                        "PublicPage.Footer:quickLinks.label.aboutUs"
                                    )}
                                </a>
                            </div>
                            <div className="item">
                                <a
                                    href="https://doclift.com/pricing/"
                                    rel="noreferrer"
                                >
                                    {t(
                                        "PublicPage.Footer:quickLinks.label.pricing"
                                    )}
                                </a>
                            </div>
                            <div className="item">
                                <a
                                    href="https://doclift.com/contact/"
                                    rel="noreferrer"
                                >
                                    {t(
                                        "PublicPage.Footer:quickLinks.label.contact"
                                    )}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="copyright--content">
                        <div className="label">
                            <span>
                                {t("PublicPage.Footer:label.docliftCopyRight")}
                            </span>
                        </div>
                        <div className="links">
                            <div className="link-item">
                                <a
                                    href="https://www.dropbox.com/scl/fi/qxbgl3zbnb7of4vqh8ts1/doclift-privacy-policy.docx?rlkey=88th2u1ifkxa0nzmjz303wizh&dl=0"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t("PublicPage.Footer:label.privacyPolicy")}
                                </a>
                            </div>
                            <div className="link-item">
                                <a
                                    href="https://www.dropbox.com/scl/fi/6mmkanfxnoim45hde8ohp/doclift-terms-of-use-User-Original.docx?rlkey=pliupipj3gwr79fp71q31g6ye&dl=0"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t(
                                        "PublicPage.Footer:label.termsOfService"
                                    )}
                                </a>
                            </div>
                            <div className="link-item">
                                <a
                                    href="https://www.dropbox.com/scl/fi/gzojkyxl20hodmg599ylm/doclift-terms-of-use-Partner.pdf?rlkey=sh28nclr9pmozrxvz0emdsqvc&dl=0"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t(
                                        "PublicPage.Footer:label.termsOfService.Partner"
                                    )}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </AppContainer>
        </footer>
    );
};
