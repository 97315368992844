import { AxiosResponse, Canceler } from "axios";
import { FinalResponse, ListResponse, SimpleObject } from "../models";
import { route, ROUTES } from "../../config";
import { EntityAPI } from "./EntityAPI";
import { API_HOST, APP_ENV } from "../config";

const {
    api_pub_public_get_all: API_GET_ALL,
    api_pub_clients_my_client_collection: API_GET_MY_CLIENT,
    api_pub_users_get_for_public_page_collection: API_GET_ALL_USERS,
    api_pub_el_courses_get_for_public_page_collection: API_GET_ALL_EL_COURSES,
    api_pub_containers_get_for_public_page_collection: API_GET_ALL_CONTAINERS,
    api_pub_conferences_get_for_public_page_collection: API_GET_ALL_CONFERENCES,
    api_pub_video_galleries_get_for_public_page_collection:
        API_GET_ALL_VIDEO_GALLERIES,
    api_pub_exhibitors_get_all_for_public_page_collection:
        API_GET_ALL_EXHIBITORS_LIST,
    api_pub_languages_get_for_public_page_collection: API_GET_ALL_LANGUAGES,
} = ROUTES;

const GENERATED_STYLE = "static/css/{id}_style.css";

export abstract class PublicAPI extends EntityAPI {
    public static async getMyClient<R>(
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(API_GET_MY_CLIENT, extraParams, {
            cancelToken: source.token,
        })
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((error) => this.handleNotFoundError(error))
            .catch((error) => this.handleUnknownError(error));
    }

    public static async getAll<R>(
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<R | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(API_GET_ALL, extraParams, {
            cancelToken: source.token,
        })
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((error) => this.handleNotFoundError(error))
            .catch((error) => this.handleUnknownError(error));
    }

    public static async getAllLanguage<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(
            API_GET_ALL_LANGUAGES,
            {
                ...extraParams,
                page,
            },
            {
                cancelToken: source.token,
            }
        )
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((error) => this.handleUnknownError(error));
    }

    public static async getAllConference<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(
            API_GET_ALL_CONFERENCES,
            {
                ...extraParams,
                page,
            },
            {
                cancelToken: source.token,
            }
        )
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((error) => this.handleUnknownError(error));
    }

    public static async getAllContainer<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(
            API_GET_ALL_CONTAINERS,
            {
                ...extraParams,
                page,
            },
            {
                cancelToken: source.token,
            }
        )
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((error) => this.handleUnknownError(error));
    }

    public static async getAllElCourse<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(
            API_GET_ALL_EL_COURSES,
            {
                ...extraParams,
                page,
            },
            {
                cancelToken: source.token,
            }
        )
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((error) => this.handleUnknownError(error));
    }

    public static async getAllUser<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(
            API_GET_ALL_USERS,
            {
                ...extraParams,
                page,
            },
            {
                cancelToken: source.token,
            }
        )
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((error) => this.handleUnknownError(error));
    }

    public static async getAllVideoGallery<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(
            API_GET_ALL_VIDEO_GALLERIES,
            {
                ...extraParams,
                page,
            },
            {
                cancelToken: source.token,
            }
        )
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((error) => this.handleUnknownError(error));
    }

    public static async getAllExhibitorList<R>(
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(API_GET_ALL_EXHIBITORS_LIST, extraParams, {
            cancelToken: source.token,
        })
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((error) => this.handleUnknownError(error));
    }

    public static async getStyle(
        clientId: number
    ): Promise<AxiosResponse<any>> {
        if (APP_ENV === "development") {
            return this.makeGet<SimpleObject<string>>(
                route("/generate/client-styles/{id}", {
                    id: clientId,
                }),
                {},
                {
                    baseURL: API_HOST,
                }
            );
        }
        return this.makeGet<SimpleObject<string>>(
            route(GENERATED_STYLE, {
                id: clientId,
            }),
            {},
            {
                baseURL: "/",
            }
        );
    }
}
