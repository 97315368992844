import {
    Conference,
    Container,
    ELCourse,
    User,
    VideoGallery,
} from "./entities";

export class PublicData {
    conferences: Conference[];

    videoGalleries: VideoGallery[];

    attendees: User[];

    courses: ELCourse[];

    containers: Container[];

    constructor({
        conferences = [],
        videoGalleries = [],
        attendees = [],
        courses = [],
        containers = [],
    }: Partial<PublicData> = {}) {
        this.conferences = conferences;
        this.videoGalleries = videoGalleries;
        this.attendees = attendees;
        this.courses = courses;
        this.containers = containers;
    }
}
export type PPublicData = Partial<PublicData>;
