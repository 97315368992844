import React, { FC } from "react";
import {
    AppContainer,
    AppSectionHeader,
    AppShowMoreButton,
    EventCard,
} from "..";
import { SectionLimit } from "../../../config";
import { useTranslation } from "../../hooks";
import { Conference } from "../../models";
import "./assets/scss/style.scss";

export interface EventOverviewProps {
    items: Conference[];
    className?: string;
    hasTitle?: boolean;
}

export const EventOverview: FC<EventOverviewProps> = ({
    items,
    className = "",
    hasTitle = false,
}): JSX.Element => {
    // hooks
    const { t } = useTranslation();

    return (
        <div className={`event-overview ${className}`} id="eventOverview">
            <AppContainer>
                {hasTitle && (
                    <AppSectionHeader
                        title={t("conference.section:label.title")}
                        subTitle={t("conference.section:label.description")}
                    />
                )}

                <div className="event-overview--items">
                    <div className="row">
                        {items.slice(0, SectionLimit.CONFERENCE).map((item) => (
                            <EventCard key={item.id} item={item} />
                        ))}
                    </div>
                </div>

                {items.length >= SectionLimit.CONFERENCE && (
                    <AppShowMoreButton
                        className="showMoreItems"
                        path="/conference"
                    />
                )}
            </AppContainer>
        </div>
    );
};
