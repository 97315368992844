import React, { FC, useState } from "react";
import { SwiperOptions } from "swiper/types/swiper-options";

import {
    AppCard,
    AppButton,
    AppSponsors,
    AppUsersList,
    AppContentModal,
    AppTextHoverTooltip,
    AppShowMoreButton,
} from "..";
import { CommonModelCardProps, Conference } from "../../models";
import {
    useBuildAssetPath,
    useDateTime,
    useRedirectHandler,
    useTranslation,
} from "../../hooks";
import {
    ConferencePosterFileInfo,
    ContainerLogoFileInfo,
    ShowMoreTextCharLength,
} from "../../../config";
import { getETrans, resolveImage, resolveImageWithStyle } from "../../utils";

import placeHolderImage from "../../assets/images/placeholders/img-thumb.svg";

import "./assets/scss/style.scss";
import { AppCreditsButton } from "../AppCreditButton";

export interface EventCardProps extends CommonModelCardProps<Conference> {
    options?: SwiperOptions;
    defCol?: number;
    xsCol?: number;
    smCol?: number;
    mdCol?: number;
    lgCol?: number;
    xlCol?: number;
}

export const EventCard: FC<EventCardProps> = ({
    item,
    className = "",
    isListView = false,
    defCol = "12",
    xsCol = "6",
    smCol = "6",
    mdCol = "6",
    lgCol = "4",
    xlCol = "3",
}): JSX.Element => {
    const {
        id,
        isLive,
        startAt,
        conferenceCategory,
        conferenceCredits,
        speakers,
        moderators,
        imageName,
        container,
        exhibitors,
    } = item;

    const title = getETrans(item, "title");
    const description = getETrans(item, "description");

    // hooks
    const { t } = useTranslation();
    const { toGetDay, toGetMonth, toGetYear, toShortTime } = useDateTime();
    const { openRegisterTab } = useRedirectHandler();

    // state
    const [showMore, setShowMore] = useState<boolean>(false);
    const [showMoreContainer, setShowMoreContainer] = useState<boolean>(false);

    // image
    const basePath = useBuildAssetPath(ConferencePosterFileInfo, container);
    const style = resolveImageWithStyle(basePath, imageName, placeHolderImage);

    const containerLogoPath = useBuildAssetPath(ContainerLogoFileInfo);
    const containerLogo = resolveImage(
        containerLogoPath,
        container.logoImageName,
        placeHolderImage
    );

    const renderRegisterButton = (btnClass: string, primary = false) => (
        <AppButton
            className={btnClass}
            variant="primary"
            onClick={() => {
                openRegisterTab(container.domain, `/event/${id}/agenda`);
            }}
        >
            {primary
                ? t("public.register.form:registerButton")
                : t("event.list:button.registration")}
        </AppButton>
    );

    return (
        <div
            className={`event-item ${className} col-${defCol} col-xs-${xsCol} col-sm-${smCol} col-md-${mdCol} col-lg-${lgCol} col-xl-${xlCol} ${
                isListView ? "listView" : "hoverView"
            }`}
            onClick={() => {
                openRegisterTab(container.domain, `/event/${id}/agenda`);
            }}
        >
            <div onClick={(e) => e.stopPropagation()}>
                <AppContentModal
                    show={showMore || showMoreContainer}
                    handleClose={() => {
                        setShowMore(false);
                        setShowMoreContainer(false);
                    }}
                    icon={"description"}
                    title={`event.list:section.title.description`}
                    size="lg"
                    keyboard
                >
                    {showMore ? (
                        <p
                            className="text"
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                            onClick={(e) => e.stopPropagation()}
                        />
                    ) : (
                        <p>{container.description}</p>
                    )}
                </AppContentModal>
            </div>

            <AppCard className="event-item--card p-0">
                <div className="banner">
                    <div className="banner--content" style={style}>
                        {isLive && (
                            <div className="liveRibbon">
                                <div className="content">
                                    <i className="fak fa-live"></i>
                                    <span>{t("event.list:badge.livenow")}</span>
                                </div>
                            </div>
                        )}

                        {conferenceCredits && (
                            <AppCreditsButton
                                conferenceCredits={conferenceCredits}
                            />
                        )}

                        {startAt && (
                            <div className="dateBadge">
                                <div className="content">
                                    <div className="day">
                                        <span>
                                            {toGetDay(new Date(startAt))}
                                        </span>
                                    </div>
                                    <div className="time">
                                        <span className="month">
                                            {toGetMonth(new Date(startAt))}
                                        </span>
                                        <span className="clock">
                                            {toShortTime(new Date(startAt))}
                                        </span>
                                    </div>
                                    <div className="year">
                                        <span>
                                            {toGetYear(new Date(startAt))}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="sponsors">
                            <AppSponsors
                                options={{
                                    slidesPerView: 4,
                                    loop: true,
                                    autoplay: {
                                        delay: 1000,
                                        disableOnInteraction: true,
                                    },
                                    freeMode: true,
                                    speed: 1000,
                                }}
                                data={exhibitors}
                                container={container}
                            />
                        </div>
                    </div>

                    {conferenceCategory && (
                        <div className="category">
                            <div
                                className="content"
                                style={{
                                    "--event-category-background-color":
                                        conferenceCategory?.color,
                                    backgroundColor: conferenceCategory?.color,
                                }}
                            >
                                <span
                                    style={{
                                        color: conferenceCategory.textColor,
                                    }}
                                >
                                    {getETrans(conferenceCategory, "name")}
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                <div className="context">
                    <div className="context--inner">
                        <div className="header mb-2">
                            <div className="title">
                                <AppTextHoverTooltip text={title}>
                                    <h3>{title}</h3>
                                </AppTextHoverTooltip>
                            </div>
                        </div>

                        <div className="desc">
                            <p
                                id="description"
                                className="text"
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </div>

                        <div className="actions">
                            {description &&
                                description.length > ShowMoreTextCharLength && (
                                    <AppShowMoreButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowMore(true);
                                        }}
                                    />
                                )}
                            {!isListView && (
                                <div className="registerBtn">
                                    {renderRegisterButton("registerBtn", true)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="additional-info">
                    <div className="additional-info--inner">
                        <div className="users">
                            <AppUsersList
                                users={[...speakers, ...moderators]}
                                hasTitle
                                title="event.list:label.participants"
                            />
                        </div>

                        {container && (
                            <div className="presenters">
                                <div className="presenters--inner">
                                    <div className="title mb-2">
                                        <h4>
                                            <i className="fak fa-handshake-alt-light"></i>
                                            <span>
                                                {t(
                                                    "event.list:label.presenter"
                                                )}
                                            </span>
                                        </h4>
                                    </div>

                                    <div className="content pl-4">
                                        <div className="presenterItem d-flex">
                                            <div className="picture pr-3">
                                                <img src={containerLogo} />
                                            </div>

                                            <div className="details">
                                                <div className="name">
                                                    <AppTextHoverTooltip
                                                        text={container.name}
                                                    >
                                                        <h5>
                                                            {container.name}
                                                        </h5>
                                                    </AppTextHoverTooltip>
                                                </div>

                                                <div className="desc">
                                                    <p>
                                                        {container.description}
                                                    </p>

                                                    {container.description &&
                                                        container.description
                                                            .length >
                                                            ShowMoreTextCharLength && (
                                                            <AppShowMoreButton
                                                                className="mt-1"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    setShowMoreContainer(
                                                                        true
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isListView && renderRegisterButton("registerBtnEnd")}
            </AppCard>
        </div>
    );
};
