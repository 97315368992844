import { Configuration, DesignConfiguration } from "../models";

export const parseDesign = (container: any): DesignConfiguration => {
    let design: DesignConfiguration = new DesignConfiguration();
    if (container?.designConfiguration) {
        design = { ...design, ...container.designConfiguration };
    }

    return design;
};
export const parseConfiguration = (container: any): Configuration => {
    const configuration: Configuration = new Configuration();
    if (container?.configuration) {
        return { ...configuration, ...container.configuration };
    }
    return configuration;
};

export const getIdFromResourceUrl = (value: string | undefined): number => {
    const valueToString = value || "";
    const slashSplit = valueToString.split("/");
    return Number(slashSplit[slashSplit.length - 1]) || 0;
};

export const getETrans = (entity: any, key: string): string => {
    if (!entity) {
        return "";
    }

    if (entity[key]) {
        return entity[key];
    }

    if (entity.translations && Object.keys(entity.translations)?.length > 0) {
        const [firstKey] = Object.keys(entity.translations);

        return entity.translations[firstKey]?.[key] || "";
    }

    return "";
};

export const itemsPerPage = 30;

export const registerPath = "/auth/register";
