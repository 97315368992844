import React, { FC } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "../../hooks";
import "./assets/scss/style.scss";

export interface AppActionDropDownProps {
    children?: JSX.Element[] | JSX.Element;
    className?: string;
    btnClassName?: string;
    isPopover?: boolean;
    text?: string;
    icon?: string;
    isEllipsis?: boolean;
}

export const AppActionDropDown: FC<AppActionDropDownProps> = ({
    children,
    className = "",
    btnClassName = "",
    isPopover = false,
    text,
    icon = "fak fa-ellipsis-solid",
    isEllipsis = true,
}): JSX.Element => {
    const { t } = useTranslation();

    const renderButton = (id = "") => (
        <Button
            id={id}
            variant="secondary"
            className={`dropdown-toggle ${
                isEllipsis ? "square-md" : "withDefaultText"
            } ${btnClassName}`}
        >
            <i className={icon} />
            {text && <span className="ml-2">{t(text)}</span>}
        </Button>
    );

    const renderContent = () => (
        <ul className="dropdown-content">
            {/* 
                we can use this structure to implement styles for
                each item automatically <li><a><i></i></a></li>.
            */}
            {children}
        </ul>
    );

    return (
        <div className={`action-dropdown dropdown ${className}`}>
            {isPopover ? (
                <OverlayTrigger
                    trigger={"click"}
                    placement="bottom-end"
                    flip
                    rootClose
                    overlay={
                        <Popover
                            id="popover-basic"
                            className="dropdown-popover-action"
                        >
                            {renderContent()}
                        </Popover>
                    }
                >
                    {renderButton()}
                </OverlayTrigger>
            ) : (
                <>
                    {renderButton("dropdownMenuButton")}

                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        {renderContent()}
                    </div>
                </>
            )}
        </div>
    );
};
