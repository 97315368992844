import React, { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { SwiperOptions } from "swiper/types/swiper-options";
import Carousel from "react-multi-carousel";

import { AppSponsor } from "./AppSponsor";
import { useExhibitors } from "../../hooks";
import { appExhibitors } from "../../atoms";
import { Container, Exhibitor, PrimitiveObject } from "../../models";
import { AppLoader } from "../AppLoader";

import "./assets/scss/style.scss";

interface AppSponsorsType {
    data: string[];
    container: Container;
    options?: SwiperOptions;
    customCss?: PrimitiveObject;
}

export const AppSponsors: FC<AppSponsorsType> = ({
    data,
    container,
    options = {},
    customCss,
}) => {
    const responsive = customCss || {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024,
            },
            items: 4,
            partialVisibilityGutter: 40,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4,
            partialVisibilityGutter: 20,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 5,
            partialVisibilityGutter: 20,
        },
    };

    const { loading } = useExhibitors();
    const [exhibitors, setExhibitors] = useState<Exhibitor[]>([]);

    const [fetchedExhibitors] = useRecoilState(appExhibitors);

    useEffect(() => {
        if (fetchedExhibitors?.length)
            setExhibitors(
                fetchedExhibitors.filter((i) => data.includes(i["@id"]))
            );
    }, []);

    if (loading) {
        return <AppLoader />;
    }

    return (
        <div className={"app-sponsors"}>
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay
                autoPlaySpeed={1}
                centerMode={false}
                className="carousel-style"
                containerClass="container-with-dots"
                customTransition="all 1s linear"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                showDots={false}
                sliderClass="slider-class"
                swipeable
                transitionDuration={1000}
                {...options}
            >
                {exhibitors.map((exhibitor) => (
                    <div key={exhibitor.id}>
                        <AppSponsor item={exhibitor} container={container} />
                    </div>
                ))}
            </Carousel>
        </div>
    );
};
