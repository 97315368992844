import React, { FC } from "react";

import { User } from "../../models";
import {
    AppContainer,
    AppSectionHeader,
    AttendeeCard,
    AppShowMoreButton,
} from "..";
import { useTranslation } from "../../hooks";

import "./assets/scss/style.scss";
import { SectionLimit } from "../../../config";

export interface AttendeeOverviewProps {
    items: User[];
    className?: string;
    hasTitle?: boolean;
}

export const AttendeeOverview: FC<AttendeeOverviewProps> = ({
    items,
    className = "",
    hasTitle = false,
}): JSX.Element => {
    // hooks
    const { t } = useTranslation();

    return (
        <div className={`attendee-overview ${className}`} id="attendeeOverview">
            <AppContainer>
                {hasTitle && (
                    <AppSectionHeader
                        title={t("attendee.section:label.title")}
                        subTitle={t("attendee.section:label.description")}
                    />
                )}

                <div className="attendee-overview--items">
                    <div className="row">
                        {items.slice(0, SectionLimit.ATTENDEE).map((item) => (
                            <AttendeeCard key={item.id} item={item} />
                        ))}
                    </div>
                </div>

                {items.length >= SectionLimit.ATTENDEE && (
                    <AppShowMoreButton
                        className="showMoreItems"
                        path="/attendee"
                    />
                )}
            </AppContainer>
            <div className="arrowHead"></div>
        </div>
    );
};
