import { formatDistance, parse } from "date-fns";
import { humanReadableDate } from "../utils";

type DateTimeType = {
    parse: (date: string, format: string) => Date;
    toShortDate: (date: Date) => string;
    toShortTime: (date: Date) => string;
    toLongDate: (date: Date) => string;
    toLongTime: (date: Date) => string;
    toLongDateTime: (date: Date) => string;
    toShortDateTime: (date: Date) => string;
    toGetDay: (date: Date) => string;
    toGetDayOfWeak: (date: Date) => string;
    toGetSortDayOfWeak: (date: Date) => string;
    toGetMonth: (date: Date) => string;
    toGetSortMonth: (date: Date) => string;
    toGetYear: (date: Date) => string;
    timeAgo: (date: Date) => string;
};

export const defaultDateTimeFormat = {
    shortDate: "EEEE MMMM, dd",
    longDate: "EEEE MMMM, dd",
    shortTime: "hh:mm a",
    longTime: "hh:mm a",
    shortDatetime: "EEEE MMMM, dd hh:mm a",
    longDatetime: "EEEE MMMM, dd hh:mm a",
    getDay: "dd",
    getDayOfWeak: "EEEE",
    getSortDayOfWeak: "EEE",
    getMonth: "MMMM",
    getSortMonth: "MMM",
    getYear: "yyyy",
};

export function useDateTime(): DateTimeType {
    const {
        shortTime,
        shortDate,
        longDate,
        longTime,
        shortDatetime,
        longDatetime,
        getDay,
        getDayOfWeak,
        getSortDayOfWeak,
        getMonth,
        getSortMonth,
        getYear,
    } = defaultDateTimeFormat;

    const toShortDate = (date: Date): string => {
        return humanReadableDate(
            date,
            shortDate,
            defaultDateTimeFormat.shortDate
        );
    };
    const toShortTime = (date: Date): string => {
        return humanReadableDate(
            date,
            shortTime,
            defaultDateTimeFormat.shortTime
        );
    };

    const toLongDate = (date: Date): string => {
        return humanReadableDate(
            date,
            longDate,
            defaultDateTimeFormat.longDate
        );
    };

    const toLongTime = (date: Date): string => {
        return humanReadableDate(
            date,
            longTime,
            defaultDateTimeFormat.longTime
        );
    };

    const toLongDateTime = (date: Date): string => {
        return humanReadableDate(
            date,
            longDatetime,
            defaultDateTimeFormat.longDatetime
        );
    };

    const toShortDateTime = (date: Date): string => {
        return humanReadableDate(
            date,
            shortDatetime,
            defaultDateTimeFormat.shortDatetime
        );
    };

    const parseDate = (date: string, dateFormat: string): Date => {
        return parse(date, dateFormat, new Date());
    };

    const toGetDay = (date: Date): string => {
        return humanReadableDate(date, getDay, defaultDateTimeFormat.getDay);
    };

    const toGetDayOfWeak = (date: Date): string => {
        return humanReadableDate(
            date,
            getDayOfWeak,
            defaultDateTimeFormat.getDayOfWeak
        );
    };

    const toGetSortDayOfWeak = (date: Date): string => {
        return humanReadableDate(
            date,
            getSortDayOfWeak,
            defaultDateTimeFormat.getSortDayOfWeak
        );
    };

    const toGetMonth = (date: Date): string => {
        return humanReadableDate(
            date,
            getMonth,
            defaultDateTimeFormat.getMonth
        );
    };

    const toGetSortMonth = (date: Date): string => {
        return humanReadableDate(
            date,
            getSortMonth,
            defaultDateTimeFormat.getSortMonth
        );
    };

    const toGetYear = (date: Date): string => {
        return humanReadableDate(date, getYear, defaultDateTimeFormat.getYear);
    };

    const timeAgo = (date: Date): string => {
        try {
            return formatDistance(date, new Date(), {
                includeSeconds: true,
            });
        } catch (error) {
            return "";
        }
    };

    return {
        parse: parseDate,
        toShortDate,
        toShortTime,
        toLongDate,
        toLongTime,
        toLongDateTime,
        toShortDateTime,
        toGetDay,
        toGetDayOfWeak,
        toGetSortDayOfWeak,
        toGetMonth,
        toGetSortMonth,
        toGetYear,
        timeAgo,
    };
}
