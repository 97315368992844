import { CONSTANTS } from "../../../config";
import { JsonResponseData } from "../apis";
import { BaseEntity } from "./BaseEntity";
import { Container } from "./Container";

export class Client extends BaseEntity {
    name: string;

    notes: string;

    storage: string;

    bucketKey: string;

    bucketSecret: string;

    bucketName: string;

    bucketRegion: string;

    bucketEndpoint: string;

    containers: Container[];

    constructor({
        name = "",
        notes = "",
        storage = CONSTANTS.Container.STORAGE.STORAGE_LOCAL,
        bucketKey = "",
        bucketSecret = "",
        bucketName = "",
        bucketRegion = "",
        bucketEndpoint = "",
        containers = [],
        id,
        createdAt,
        updatedAt,
    }: Partial<Client> = {}) {
        super(id, createdAt, updatedAt);
        this.name = name;
        this.notes = notes;
        this.storage = storage;
        this.bucketKey = bucketKey;
        this.bucketSecret = bucketSecret;
        this.bucketName = bucketName;
        this.bucketRegion = bucketRegion;
        this.bucketEndpoint = bucketEndpoint;
        this.containers = containers;
    }

    toJSON(addExtraData = false): JsonResponseData {
        return {
            ...super.toJSON(addExtraData),
            name: this.name,
            notes: this.notes,
            storage: this.storage,
            bucketKey: this.bucketKey,
            bucketSecret: this.bucketSecret,
            bucketName: this.bucketName,
            bucketRegion: this.bucketRegion,
            bucketEndpoint: this.bucketEndpoint,
            containers: this.containers,
        };
    }
}
