import React, { FC, PropsWithChildren } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "../../hooks";
import "./assets/scss/style.scss";

interface AppContentModalProps extends PropsWithChildren {
    show: boolean;
    handleClose: (show: boolean) => void;
    title?: string;
    icon?: string;
    className?: string;
    size?: "sm" | "lg" | "xl";
    keyboard?: boolean;
}

export const AppContentModal: FC<AppContentModalProps> = ({
    show,
    handleClose,
    icon,
    title,
    className = "",
    size = "sm",
    keyboard = false,
    ...props
}): JSX.Element => {
    const { t } = useTranslation();
    const iconString = icon?.includes(" ") ? icon : `fak fa-${icon}`;

    return (
        <Modal
            show={show}
            onHide={() => handleClose(false)}
            backdrop="static"
            keyboard={keyboard}
            size={size}
            className={`content-modal-dialog ${className}`}
        >
            {(title || icon) && (
                <Modal.Header closeButton>
                    <h4 className="mb-0">
                        <i className={`${iconString} mr-2`}></i>
                        {t(title)}
                    </h4>
                    <span className="close-icon">
                        <i className="fak fa-times-light" />
                    </span>
                </Modal.Header>
            )}
            <Modal.Body>{props.children}</Modal.Body>
        </Modal>
    );
};
