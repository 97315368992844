import React from "react";
import { Redirect, Router } from "@reach/router";

import { ModuleRouter } from "./models/ModuleRouter";
import {
    PublicPage,
    ConferencePage,
    VideoGalleryPage,
    AttendeePage,
    CoursePage,
    ContainerPage,
    ReloadingPage,
} from "./pages";

export const routers: ModuleRouter[] = [
    {
        key: "app-module",
        path: "/*",
        layout: "app",
        RouterPlug: (): JSX.Element => {
            return (
                <Router>
                    <Redirect from="*" to="/" noThrow />
                    <PublicPage path="/" />
                    <ReloadingPage path="/reloading" />
                    <ConferencePage path="/conference" />
                    <VideoGalleryPage path="/video-gallery" />
                    <AttendeePage path="/attendee" />
                    <CoursePage path="/course" />
                    <ContainerPage path="/container" />
                </Router>
            );
        },
    },
];

export default routers;
