import React, { FC } from "react";
import {
    AppContainer,
    AppSectionHeader,
    AppShowMoreButton,
    VideoCard,
} from "..";
import { SectionLimit } from "../../../config";
import { useTranslation } from "../../hooks";
import { VideoGallery } from "../../models";
import "./assets/scss/style.scss";

export interface VideoOverviewProps {
    items: VideoGallery[];
    className?: string;
    hasTitle?: boolean;
}

export const VideoOverview: FC<VideoOverviewProps> = ({
    items,
    className = "",
    hasTitle = false,
}): JSX.Element => {
    // hooks
    const { t } = useTranslation();

    return (
        <div className={`video-overview ${className}`} id="videoOverview">
            <AppContainer>
                {hasTitle && (
                    <AppSectionHeader
                        title={t("videoGallery.section:label.title")}
                        subTitle={t("videoGallery.section:label.description")}
                    />
                )}

                <div className="video-overview--items">
                    <div className="row">
                        {items
                            .slice(0, SectionLimit.VIDEO_GALLERY)
                            .map((item) => (
                                <VideoCard key={item.id} item={item} />
                            ))}
                    </div>
                </div>

                {items.length >= SectionLimit.VIDEO_GALLERY && (
                    <AppShowMoreButton
                        className="showMoreItems"
                        path="/video-gallery"
                    />
                )}
            </AppContainer>
        </div>
    );
};
