export interface Configuration {
    isChatEnable: boolean;
    isA3dEnable: boolean;
    isHubspotEnable: boolean;
    googleAnalyticsCode: string;
    hubspotId: string;
    projectName: string;
    isMusicEnable: boolean;
    isMusicLoop: boolean;
    musicFilename: string;
    isAuth2wayEnable: boolean;
    auth2wayRole: string[];
    isLiveNowEnable: boolean;
    isPipEnable: boolean;
    excludeDomainsFromAnalytics: string;
    shortDate: string;
    longDate: string;
    shortTime: string;
    longTime: string;
    longDatetime: string;
    shortDatetime: string;
    isDisclaimerEnable: boolean;
    isLoginGoogleEnable: boolean;
    isLoginLinkedinEnable: boolean;
    isLoginAzureEnable: boolean;
    isLoginSwissRxEnable: boolean;
    isOnboardingEnable: boolean;
    filterUserRoles: string[];
    isBookingEnable: boolean;
    isRegistrationEnable: boolean;
    isRegistrationEmailVerificationEnable: boolean;
    isVideoCallEnable: boolean;
    isElearningEnable: boolean;
    isNewsfeedEnable: boolean;
    isVoucherEnable: boolean;
    isRegisterOnlyWithVoucherEnable: boolean;
    isSsoCsEnable: boolean;
    isWebexEnable: boolean;
    webexId: string;
    webexSecret: string;
    isPersonalPasswordEnable: boolean;
    translations: [];
    isAttendeeBadgeEnable: boolean;
    isAttendeeProfileDetailEnable: boolean;
    isStrongPasswordEnable: boolean;
    notificationSoundFilename: string;

    isShowFeedEventBlock: boolean;
    positionFeedEventBlock: number;
    isShowFeedVideoBlock: boolean;
    positionFeedVideoBlock: number;
    isShowAccessLogBlock: boolean;
    positionFeedAccessLogBlock: number;
    isShowCourseBlock: boolean;
    positionFeedCourseBlock: number;
    isSurveyEnable: boolean;

    entryPointPath: string;
    entryPointMobilePath: string;

    publicPageBannerMainText: string;
    publicPageBannerSubText: string;
}

export class Configuration implements Configuration {
    isChatEnable = false;

    isA3dEnable = false;

    isHubspotEnable = false;

    googleAnalyticsCode = "";

    hubspotId = "";

    projectName = "";

    isMusicEnable = false;

    isMusicLoop = false;

    musicFilename = "";

    isAuth2wayEnable = false;

    auth2wayRole = [""];

    isLiveNowEnable = false;

    isPipEnable = false;

    shortDate = "";

    longDate = "";

    shortTime = "";

    longTime = "";

    shortDatetime = "";

    longDatetime = "";

    translations = [];

    isDisclaimerEnable = false;

    isLoginGoogleEnable = false;

    isLoginLinkedinEnable = false;

    isLoginAzureEnable = false;

    isLoginSwissRxEnable = false;

    isOnboardingEnable = false;

    filterUserRoles = [""];

    isBookingEnable = false;

    isRegistrationEnable = false;

    isRegistrationEmailVerificationEnable = true;

    isVideoCallEnable = false;

    isElearningEnable = false;

    isNewsfeedEnable = false;

    isVoucherEnable = false;

    isRegisterOnlyWithVoucherEnable = false;

    isSsoCsEnable = false;

    isWebexEnable = false;

    webexId = "";

    webexSecret = "";

    isUserSocialEnable = false;

    isPersonalPasswordEnable = true;

    loginCtaUrl: string | undefined;

    isAttendeeBadgeEnable = true;

    isAttendeeProfileDetailEnable = true;

    isStrongPasswordEnable = true;

    notificationSoundFilename = "";

    isShowFeedEventBlock = true;

    positionFeedEventBlock = 1;

    isShowFeedVideoBlock = true;

    positionFeedVideoBlock = 2;

    isShowAccessLogBlock = true;

    positionFeedAccessLogBlock = 3;

    isShowCourseBlock = true;

    isSurveyEnable = false;

    positionFeedCourseBlock = 4;

    entryPointPath = "";

    entryPointMobilePath = "";

    publicPageBannerMainText = "";

    publicPageBannerSubText = "";
}

export const useDefaultOnEmpty = [
    // "shortDate",
    // "longDate",
    // "shortTime",
    // "longTime",
    // "shortDatetime",
    // "longDatetime",
];
