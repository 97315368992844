import React, { useLayoutEffect, useRef } from "react";

import {
    BUFFER,
    CHAR_WIDTH,
    convertPercents,
    convertRotates,
    MAX_LENGTH,
} from "./helper";

import "./assets/scss/style.scss";

interface AppBadgeBackgroundProps {
    text: string;
    isOnline: boolean;
}

export const AppBadgeBackground: React.FC<AppBadgeBackgroundProps> = ({
    text,
    isOnline,
}) => {
    const rightRef = useRef<HTMLSpanElement>(null);
    const leftRef = useRef<HTMLSpanElement>(null);
    const missingLen = MAX_LENGTH - text.length;

    useLayoutEffect(() => {
        if (leftRef.current && rightRef.current && text) {
            const rotate = Math.round(missingLen / 2) * CHAR_WIDTH + BUFFER;

            rightRef.current.style.transform = `rotate(${
                convertRotates[missingLen] || rotate
            }deg)`;
            leftRef.current.style.transform = `rotate(-${
                convertRotates[missingLen] || rotate
            }deg)`;
        }
    }, [missingLen, text]);
    return (
        <div className="badge-container p-0">
            <div
                className="progress"
                data-percentage={convertPercents[missingLen] || 0}
            >
                <span ref={leftRef} className="progress-left">
                    <span
                        className={`progress-bar ${
                            isOnline ? "online" : "offline"
                        }`}
                    ></span>
                </span>
                <span ref={rightRef} className="progress-right">
                    <span
                        className={`progress-bar ${
                            isOnline ? "online" : "offline"
                        }`}
                    ></span>
                </span>
            </div>
        </div>
    );
};
