import React, { FC, PropsWithChildren } from "react";
import { Container } from "react-bootstrap";
import { AppHeader, AppFooter } from "../../components";

export const AppLayout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Container className={"p-0"} fluid>
            <AppHeader />
            {children}
            <AppFooter />
        </Container>
    );
};
