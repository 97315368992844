import React, { FC, PropsWithChildren } from "react";
import { Card } from "react-bootstrap";

import "./assets/scss/style.scss";

export interface AppCardProps extends PropsWithChildren {
    className?: string;
    id?: string;
}

export const AppCard: FC<AppCardProps> = ({
    children,
    className = "",
    id,
}): JSX.Element => {
    return (
        <Card
            id={id}
            className={`col ${className}`}
            style={{ overflow: "visible" }}
        >
            {children}
        </Card>
    );
};
