import React, { FC, useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";

import {
    AppCard,
    AppTextHoverTooltip,
    AppButton,
    AppContentModal,
    AppBadgeAvatar,
} from "..";
import {
    useBuildAssetPath,
    useTranslation,
    useUserJobCompany,
} from "../../hooks";
import { CommonModelCardProps, User } from "../../models";
import placeHolderImage from "../../assets/images/placeholders/user-thumb.png";
import { getIdFromResourceUrl, resolveImageWithStyle } from "../../utils";
import { AppUserListDisplayLength, UserProfileFileInfo } from "../../../config";

import "./assets/scss/styles.scss";

interface AttendeeCardProps extends CommonModelCardProps<User> {
    id?: string;
}

export const AttendeeCard: FC<AttendeeCardProps> = ({
    item,
    // isListView = false,
}): JSX.Element => {
    const {
        id,
        userType,
        salutation,
        firstName,
        lastName,
        imageName = "",
        country,
        jobTitle,
        company,
        userTags = [],
        userGroups = [],
        saFacebook,
        saInstagram,
        saLinkedin,
        saTwitter,
        client,
    } = item;

    // hooks
    const { t } = useTranslation();
    const { displayJobCompany } = useUserJobCompany();

    // image
    const basePath = useBuildAssetPath(UserProfileFileInfo, client);
    const style = resolveImageWithStyle(basePath, imageName, placeHolderImage);

    const [showMore, setShowMore] = useState<boolean>(false);
    const [userGroupIds, setUserGroupIds] = useState<number[]>([]);

    useEffect(() => {
        setUserGroupIds(
            userGroups.map((groupId) => getIdFromResourceUrl(groupId))
        );
    }, []);

    return (
        <div className="attendee-item col-12 col-sm-6 col-lg-4 col-xl-2">
            <AppContentModal
                show={showMore}
                handleClose={setShowMore}
                icon={"tags"}
                title={"attendee.form:label.tags"}
            >
                {userTags.map((tag) => (
                    <div className={"modal-conference-tags"} key={tag.name}>
                        {tag.name}
                    </div>
                ))}
            </AppContentModal>

            <AppCard className="attendee-item--inner">
                <div className="avatar mt-4">
                    <a className={`profile-avatar`}>
                        <i style={style}>
                            {userType && id && (
                                <AppBadgeAvatar
                                    text={userType}
                                    isOnline={false}
                                    key={id}
                                />
                            )}
                        </i>
                    </a>
                </div>

                <div className="title text-center">
                    <span className="salutation">{salutation}</span>

                    <a className="title--name">
                        <div className="d-flex justify-content-center align-items-center">
                            <AppTextHoverTooltip
                                text={firstName + lastName}
                                placement={"bottom"}
                            >
                                <h2 className="mb-0">
                                    {firstName} {lastName}
                                </h2>
                            </AppTextHoverTooltip>

                            <div className="user-flag f16 pl-1">
                                <div className={`flag ${country}`}></div>
                            </div>
                        </div>
                    </a>

                    <div className="title--bio mb-1 mx-2">
                        <AppTextHoverTooltip
                            text={displayJobCompany(jobTitle, company)}
                            placement={"bottom"}
                        >
                            <p className={"mb-0"}>
                                {displayJobCompany(jobTitle, company)}
                            </p>
                        </AppTextHoverTooltip>
                    </div>
                </div>

                <div className="social mb-3">
                    <div className="row m-0 p-o social--wrapper">
                        {saFacebook && (
                            <div className="col-auto p-0 mr-2">
                                <a
                                    href={saFacebook}
                                    className="btn btn-secondary"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fak fa-es-facebook"></i>
                                </a>
                            </div>
                        )}
                        {saInstagram && (
                            <div className="col-auto p-0 mr-2">
                                <a
                                    href={saInstagram}
                                    className="btn btn-secondary"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fak fa-es-instagram"></i>
                                </a>
                            </div>
                        )}
                        {saLinkedin && (
                            <div className="col-auto p-0 mr-2">
                                <a
                                    href={saLinkedin}
                                    className="btn btn-secondary"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fak fa-es-linkedin"></i>
                                </a>
                            </div>
                        )}
                        {saTwitter && (
                            <div className="col-auto p-0 mr-2">
                                <a
                                    href={saTwitter}
                                    className="btn btn-secondary"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fak fa-es-twitter"></i>
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                <div
                    className={`tags ${
                        userTags.length < AppUserListDisplayLength ? "lth2" : ""
                    }`}
                >
                    <div className="row m-0 p-0">
                        {userTags
                            ?.slice(0, AppUserListDisplayLength)
                            .map((tag) => (
                                <div
                                    className="tags--item col-auto px-0 mb-2"
                                    key={tag.name}
                                >
                                    <AppTextHoverTooltip
                                        text={tag.name as string}
                                        placement={"top"}
                                    >
                                        <span>{tag.name}</span>
                                    </AppTextHoverTooltip>
                                </div>
                            ))}

                        {userTags.length > AppUserListDisplayLength && (
                            <Link
                                to="#"
                                onClick={() => {
                                    setShowMore(true);
                                }}
                                className="showMore pl-2"
                            >
                                <i>+</i>
                                <span>
                                    {userTags.length - AppUserListDisplayLength}{" "}
                                </span>
                            </Link>
                        )}
                    </div>
                </div>

                <div className="buttons mt-3 mb-3">
                    <AppButton
                        variant="primary"
                        onClick={() => {
                            navigate(
                                `/container?user-group=${userGroupIds}&id=${id}`
                            );
                        }}
                    >
                        {t("attendee.form:button.connect")}
                    </AppButton>
                </div>
            </AppCard>
        </div>
    );
};
