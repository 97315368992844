import { AxiosRequestConfig } from "axios";
import { USER_LOCALE } from "../../config/app-env";
import { generateKeyHeader, getTimeZone } from "../../utils";

export const onRequestFulfilled = (
    config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
    config.headers["ES-CKEY"] = generateKeyHeader();

    config.headers["ES-TIMEZONE"] = getTimeZone();
    // @TODO: why we need this, ask daniel ?
    config.headers["Content-Type"] = "application/ld+json";

    const locale = localStorage.getItem(USER_LOCALE);
    if (config.params) {
        // eslint-disable-next-line @typescript-eslint/dot-notation, no-console,no-param-reassign
        if (locale) {
            config.params.locale = locale;
        }
    }
    return config;
};

export const onRequestRejected = (): null => {
    return null;
};
