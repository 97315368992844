const MINUS = 4;
export const convertRotates = {
    0: 180,
    1: 180 - MINUS,
    2: 187 - MINUS,
    3: 190 - MINUS,
    4: 198 - MINUS,
    5: 200 - MINUS,
    7: 222 - MINUS,
    8: 222 - MINUS,
    9: 228 - MINUS,
    10: 235 - MINUS,
    11: 242 - MINUS,
    12: 248 - MINUS,
    13: 250 - MINUS,
    14: 258 - MINUS,
    15: 260 - MINUS,
};

export const convertPercents = {
    0: 54,
    1: 50,
    2: 49,
    3: 45,
    4: 43,
    5: 39,
    7: 32,
    8: 29,
    9: 25,
    10: 22,
    11: 18,
    12: 15,
    13: 11,
    14: 10,
    15: 6,
};

export const BUFFER = 65;
export const MAX_LENGTH = 16;
export const CHAR_WIDTH = 15;
