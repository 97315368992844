import React from "react";
import { intervalToDuration } from "date-fns";
import { useTranslation } from "../../hooks";

interface AppDurationProps {
    duration: number | string;
    className?: string;
    unit?: "year" | "month" | "week" | "day" | "hour" | "minute";
}

const convertUnits = {
    hour: 60,
    minute: 1,
};

export const AppDuration: React.FC<AppDurationProps> = ({
    duration,
    className = "",
    unit = "minute",
}) => {
    // const formatDuration = durationFormatter(Number(duration ?? 0));
    const convertNumber: number = convertUnits[unit] || 1;
    const seconds: number = Number(duration) * convertNumber * 60 * 1000;
    const formatDuration = intervalToDuration({
        start: 0,
        end: seconds,
    });
    const { t } = useTranslation();

    const getUnixTimeLabel = (
        unixTime: number | undefined,
        unixLabel
    ): string => {
        let text = "";
        if (unixTime && unixTime > 0) {
            text += `${unixTime} `;
            text +=
                unixTime > 1
                    ? t(`common.date:wide.${unixLabel}s`)
                    : t(`common.date:wide.${unixLabel}`);
        }
        return text;
    };

    const years = getUnixTimeLabel(formatDuration.months, "year");
    const months = getUnixTimeLabel(formatDuration.months, "month");
    const weeks = getUnixTimeLabel(formatDuration.weeks, "week");
    const days = getUnixTimeLabel(formatDuration.days, "day");
    const hours = getUnixTimeLabel(formatDuration.hours, "hour");
    const minutes = getUnixTimeLabel(formatDuration.minutes, "minute");
    let text = "";

    if (years) {
        text += years;
    }
    if (months) {
        if (years) {
            text += ", ";
        }
        text += months;
    }
    if (weeks) {
        if (months) {
            text += ", ";
        }
        text += weeks;
    }
    if (days) {
        if (weeks) {
            text += ", ";
        }
        text += days;
    }
    if (hours) {
        if (days) {
            text += ", ";
        }
        text += hours;
    }
    if (minutes) {
        if (hours) {
            text += ", ";
        }
        text += minutes;
    }
    return (
        <div className={`mb-0 ${className}`}>
            {text || `0 ${t(`common.date:wide.minute`)}`}
        </div>
    );
};
