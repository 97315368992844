import { useTranslation as UseTrans } from "react-i18next";

export const useTranslation: any = () => {
    const { t } = UseTrans();
    const debugModeOn = localStorage.getItem("translation-debug-mode");

    const translate = (translationKey) => {
        if (debugModeOn === "testing" && t(translationKey) === translationKey) {
            return translationKey?.split(":")?.pop()?.split(".")?.pop();
        }

        return debugModeOn === "true" ? translationKey : t(translationKey);
    };

    return { t: translate };
};
