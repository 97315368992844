import React, { FC, useEffect, useState, useRef, useCallback } from "react";
import { Nav, Navbar } from "react-bootstrap";

import { AppContainer, AppButton, AppActionDropDown } from "./../../components";
import { useBuildAssetPath, useTranslation } from "../../hooks";
import placeHolderImage from "../../assets/images/logo/vlogo.png";

import "./assets/scss/styles.scss";
import { DesignConfigurationFileInfo } from "../../../config";
import { useGlobalData } from "../../contexts";
import { resolveImage } from "../../utils";
import { Link, navigate, useLocation } from "@reach/router";
import { USER_LOCALE } from "../../config";

export const AppHeader: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const {
        mainContainer,
        designConfig: { genImageNavigationLogo },
        activeLanguages,
    } = useGlobalData();
    const [locale, setLocale] = useState<string>(
        localStorage.getItem(USER_LOCALE) || ""
    );

    const toggleMenu = useRef<HTMLDivElement>(null);

    const [navOpen, isNavOpen] = useState<boolean>(false);
    const navItems = [
        {
            path: "/conference",
            title: "conference.section:label.title",
        },
        {
            path: "/video-gallery",
            title: "videoGallery.section:label.title",
        },
        {
            path: "/attendee",
            title: "attendee.section:label.title",
        },
        {
            path: "/course",
            title: "course.section:label.title",
        },
        {
            path: "/container",
            title: "container.section:label.title",
        },
    ];

    // image
    const basePath = useBuildAssetPath(
        DesignConfigurationFileInfo,
        mainContainer
    );
    const navLogo = resolveImage(
        basePath,
        genImageNavigationLogo,
        placeHolderImage
    );

    const handleClickOutside = useCallback((event) => {
        if (
            !toggleMenu.current?.contains(event.target) &&
            !document.querySelector(".navbar-toggler")?.contains(event.target)
        ) {
            isNavOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const languages = [{ name: "All", locale: "" }, ...activeLanguages];

    return (
        <header>
            <AppContainer className="header--container">
                <Navbar expand="xl" onToggle={isNavOpen} expanded={navOpen}>
                    <Navbar.Brand className="logo-container">
                        <Link to="/">
                            <img src={navLogo} />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navigationMenu">
                        <i
                            className="fak fa-bars-regular"
                            onClick={() => {
                                isNavOpen(!navOpen);
                            }}
                        ></i>
                    </Navbar.Toggle>
                    <Navbar.Collapse
                        className="navigation-menu"
                        id="navigationMenu"
                        ref={toggleMenu}
                    >
                        <Nav className="navigation-menu--inner">
                            {navItems.map(({ path, title }) => (
                                <Nav.Item key={path}>
                                    <Link
                                        to={path}
                                        className={
                                            pathname === path ? "active" : ""
                                        }
                                        onClick={() => {
                                            isNavOpen(false);
                                        }}
                                    >
                                        <span>{t(title)}</span>
                                    </Link>
                                </Nav.Item>
                            ))}
                        </Nav>

                        {/* <Nav>
                            <Nav.Item className="searchBarCol pr-3">
                                <AppSearchBar iconLeft />
                            </Nav.Item>
                        </Nav> */}
                    </Navbar.Collapse>

                    <Nav className="right-container">
                        <div
                            className={`col-auto language-dropdown px-0 ml-2 ml-sm-3 mr-3 ${
                                !languages || languages.length === 2
                                    ? "hidden-language-button"
                                    : ""
                            }`}
                        >
                            <AppActionDropDown
                                icon={`lang ${locale || "all"}`}
                                className="language-action-pop-up"
                                btnClassName="px-3 f16"
                                isEllipsis={false}
                            >
                                {languages?.map((item) => (
                                    <li key={item.locale}>
                                        <Link
                                            to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                setLocale(item.locale);
                                                localStorage.setItem(
                                                    USER_LOCALE,
                                                    item.locale
                                                );
                                                navigate("/reloading", {
                                                    state: {
                                                        url: location.pathname,
                                                    },
                                                }).then();
                                            }}
                                            className="f16"
                                        >
                                            <i
                                                className={`lang ${
                                                    item.locale || "all"
                                                }`}
                                            />
                                            <span>{item.name}</span>
                                        </Link>
                                    </li>
                                ))}
                            </AppActionDropDown>
                        </div>
                        <Nav.Item className="signInCol pr-3">
                            <Link to="/container">{t("login.form:login")}</Link>
                        </Nav.Item>

                        <Nav.Item className="registerBtnCol">
                            <AppButton
                                className="registerBtn"
                                variant="primary"
                                onClick={() => {
                                    navigate("/container");
                                }}
                            >
                                {t("public.register.form:registerButton")}
                            </AppButton>
                        </Nav.Item>
                    </Nav>
                </Navbar>
            </AppContainer>
        </header>
    );
};
