import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-xhr-backend";

export const init = (id, lang = "en") => {
    i18n
    .use(backend)
    .use(initReactI18next).init({
        backend: {
            loadPath: id ? `/static/translations/${id}/{{lng}}.json` : "",
        },
        lng: lang,
        fallbackLng: "en",
        keySeparator: false,
        nsSeparator: "::",
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        // defaultNS: "AppModule",
        // resources,
        react: {
            // Turn off the use of React Suspense
            useSuspense: false
          }
    })
};

export default i18n;
