import { format } from "date-fns";
import { locale } from "../config/date-fns";

export const getTimeZone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const humanReadableDate = (
    date: Date,
    toFormat: string,
    defaultFormat?: string
): string => {
    try {
        return format(date, toFormat, { locale });
    } catch (e) {
        if (defaultFormat) {
            return format(date, defaultFormat, { locale });
        }
        return "Invalid";
    }
};
