// noinspection SpellCheckingInspection

import ROUTES from "./routes.json";
import CONSTANTS from "./constants.json";
import { FileTypeInfo } from "../AppModule/models";

export * from "./helper";

const R = ROUTES;
const C = CONSTANTS;
const {
    Upload,
    VoteQuestion,
    Role,
    Session,
    EmailTemplate,
    Meeting,
    MeetingBooking,
    User,
    ElTask,
    ElExam,
    ElExamQuestion,
    VideoLibrary,
    ElTaskAttempt,
    ElExamAttempt,
    EventLog,
    CommonComment,
    Newsfeed,
    SurveyTrigger,
    SurveyQuestion,
} = CONSTANTS;

const { FILETYPE: FILE_TYPE, FILETYPEINFO: FILE_TYPE_INFO } = Upload;

const {
    VOTEQUESTIONTYPE: VOTE_QUESTION_TYPE,
    VOTERESULTTYPE: VOTE_RESULT_TYPE,
    VOTEQUESTIONCHARTTYPE: VOTE_QUESTION_CHART_TYPE,
    VOTEQUESTIONSLIDERSTYLE: VOTE_QUESTION_SLIDER_STYLE,
} = VoteQuestion;

const {
    STREAMTYPE: STREAM_TYPE,
    CARDSIZE: CARD_SIZE,
    CARDTYPE: CARD_TYPE,
} = Session;

const { EVENT } = EventLog;

export const { SOURCE } = CommonComment;

export const { POSTTYPE } = Newsfeed;

// FileTypeInfo definitions
const {
    FILETYPEINFO_VOTEOPTION_POSTER,
    FILETYPEINFO_USER_PROFILE,
    FILETYPEINFO_EXHIBITOR_POSTER,
    FILETYPEINFO_EXHIBITORLOGO_POSTER,
    FILETYPEINFO_EXHIBITORVIDEO_MEDIA,
    FILETYPEINFO_EXHIBITORPRODUCT_POSTER,
    FILETYPEINFO_EXHIBITOR_DOC,
    FILETYPEINFO_CONFERENCE_POSTER,
    FILETYPEINFO_AFRAMEPANEL_MEDIA,
    FILETYPEINFO_DESIGN_CONFIGURATION,
    FILETYPEINFO_IMPORT_FILE,
    FILETYPEINFO_SESSION_POSTER,
    FILETYPEINFO_ELCOURSE_POSTER,
    FILETYPEINFO_ELTASK_POSTER,
    FILETYPEINFO_ELTASK_VIDEO,
    FILETYPEINFO_ELEXAM_VIDEO,
    FILETYPEINFO_ELEXAM_ANSWER_FILE,
    FILETYPEINFO_VIDEOLIBRARYVIDEO_MEDIA,
    FILETYPEINFO_VIDEOLIBRARY_POSTER,
    FILETYPEINFO_VIDEOGALLERYVIDEO_MEDIA,
    FILETYPEINFO_VIDEOGALLERY_POSTER,
    FILETYPEINFO_DOCUMENT_FILE,
    FILETYPEINFO_BACKGROUND_POSTER,
    FILETYPEINFO_NEWSFEED_MEDIA,
    FILETYPEINFO_COMMON_COMMENT_MEDIA,
    FILETYPEINFO_CHAT_MESSAGE_MEDIA,
    FILETYPEINFO_CONTAINER_POSTER,
    FILETYPEINFO_CONTAINER_LOGO,
} = FILE_TYPE_INFO;

const VoteOptionFileInfo = FILETYPEINFO_VOTEOPTION_POSTER as FileTypeInfo;
const UserProfileFileInfo = FILETYPEINFO_USER_PROFILE as FileTypeInfo;
const ExhibitorPosterFileInfo = FILETYPEINFO_EXHIBITOR_POSTER as FileTypeInfo;
const ExhibitorLogoPosterFileInfo =
    FILETYPEINFO_EXHIBITORLOGO_POSTER as FileTypeInfo;
const ExhibitorVideoFileInfo =
    FILETYPEINFO_EXHIBITORVIDEO_MEDIA as FileTypeInfo;
const ExhibitorProductPosterFileInfo =
    FILETYPEINFO_EXHIBITORPRODUCT_POSTER as FileTypeInfo;
const ConferencePosterFileInfo = FILETYPEINFO_CONFERENCE_POSTER as FileTypeInfo;
const ExhibitorDocFileInfo = FILETYPEINFO_EXHIBITOR_DOC as FileTypeInfo;
const AFramePanelMediaFileInfo = FILETYPEINFO_AFRAMEPANEL_MEDIA as FileTypeInfo;
const DesignConfigurationFileInfo =
    FILETYPEINFO_DESIGN_CONFIGURATION as FileTypeInfo;
const ImportFileFileInfo = FILETYPEINFO_IMPORT_FILE as FileTypeInfo;
const SessionPosterFileInfo = FILETYPEINFO_SESSION_POSTER as FileTypeInfo;
const ELCoursePosterFileInfo = FILETYPEINFO_ELCOURSE_POSTER as FileTypeInfo;
const ELTaskPosterFileInfo = FILETYPEINFO_ELTASK_POSTER as FileTypeInfo;
const ELTaskVideoFileInfo = FILETYPEINFO_ELTASK_VIDEO as FileTypeInfo;
const ELExamVideoFileInfo = FILETYPEINFO_ELEXAM_VIDEO as FileTypeInfo;
const ELExamAnswerFileInfo = FILETYPEINFO_ELEXAM_ANSWER_FILE as FileTypeInfo;
const VideoLibraryVideoFileInfo =
    FILETYPEINFO_VIDEOLIBRARYVIDEO_MEDIA as FileTypeInfo;
const VideoLibraryPosterFileInfo =
    FILETYPEINFO_VIDEOLIBRARY_POSTER as FileTypeInfo;
const VideoGalleryVideoFileInfo =
    FILETYPEINFO_VIDEOGALLERYVIDEO_MEDIA as FileTypeInfo;
const VideoGalleryPosterFileInfo =
    FILETYPEINFO_VIDEOGALLERY_POSTER as FileTypeInfo;
const DocumentHubFileInfo = FILETYPEINFO_DOCUMENT_FILE as FileTypeInfo;
const BackgroundPosterFileInfo = FILETYPEINFO_BACKGROUND_POSTER as FileTypeInfo;
const NewsFeedMediaFileInfo = FILETYPEINFO_NEWSFEED_MEDIA as FileTypeInfo;
const CommonCommentMediaFileInfo =
    FILETYPEINFO_COMMON_COMMENT_MEDIA as FileTypeInfo;
const ChatMessageMediaFileInfo =
    FILETYPEINFO_CHAT_MESSAGE_MEDIA as FileTypeInfo;
const ContainerPosterFileInfo = FILETYPEINFO_CONTAINER_POSTER as FileTypeInfo;
const ContainerLogoFileInfo = FILETYPEINFO_CONTAINER_LOGO as FileTypeInfo;

// UploadImage types
const {
    FILETYPE_VOTEOPTION_POSTER,
    FILETYPE_EXHIBITOR_POSTER,
    FILETYPE_EXHIBITORLOGO_POSTER,
    FILETYPE_USER_PROFILE,
    FILETYPE_EXHIBITORVIDEO_MEDIA,
    FILETYPE_EXHIBITORPRODUCT_POSTER,
    FILETYPE_CONFERENCE_POSTER,
    FILETYPE_EXHIBITOR_DOC,
    FILETYPE_AFRAMEPANEL_MEDIA,
    FILETYPE_CONFIGURATION,
    FILETYPE_DESIGN_CONFIGURATION,
    FILETYPE_ELCOURSE_POSTER,
    FILETYPE_ELTASK_POSTER,
    FILETYPE_ELTASK_VIDEO,
    FILETYPE_ELEXAM_VIDEO,
    FILETYPE_ELEXAM_ANSWER_FILE,
    FILETYPE_VIDEOLIBRARYVIDEO_MEDIA,
    FILETYPE_VIDEOLIBRARY_POSTER,
    FILETYPE_VIDEOGALLERY_POSTER,
    FILETYPE_VIDEOGALLERYVIDEO_MEDIA,
    FILETYPE_DOCUMENT_FILE,
    FILETYPE_BACKGROUND_POSTER,
    FILETYPE_NEWSFEED_MEDIA,
    FILETYPE_COMMON_COMMENT_MEDIA,
    FILETYPE_CHAT_MESSAGE_MEDIA,
} = FILE_TYPE;

// ELearning
const { TASKTYPE, VIDEOTASKTYPE, STREAMTYPE } = ElTask;

const { SOLUTIONTYPE, EXAMLEVEL: EXAM_LEVEL } = ElExam;

const { QUESTIONTYPE } = ElExamQuestion;

const { STATUSTASKATTEMPT: STATUS_TASK_ATTEMPT } = ElTaskAttempt;

const { SURVEYTRIGGERDISPLAYTYPE: SURVEY_TRIGGER_DISPLAY_TYPE } = SurveyTrigger;
const { SURVEYQUESTIONTYPE: SURVEY_QUESTION_TYPE } = SurveyQuestion;

const {
    STATUSEXAMATTEMPT: STATUS_EXAM_ATTEMPT,
    RESULTSTATUSEXAMATTEMPT: RESULT_STATUS_EXAM_ATTEMPT,
} = ElExamAttempt;

const VOTE_OPTION_POSTER_TYPE = FILETYPE_VOTEOPTION_POSTER;
const EXHIBITOR_POSTER_TYPE = FILETYPE_EXHIBITOR_POSTER;
const EXHIBITOR_LOGO_POSTER_TYPE = FILETYPE_EXHIBITORLOGO_POSTER;
const EXHIBITOR_VIDEO_TYPE = FILETYPE_EXHIBITORVIDEO_MEDIA;
const USER_PROFILE_TYPE = FILETYPE_USER_PROFILE;
const EXHIBITOR_PRODUCT_POSTER_TYPE = FILETYPE_EXHIBITORPRODUCT_POSTER;
const CONFERENCE_POSTER_TYPE = FILETYPE_CONFERENCE_POSTER;
const EXHIBITOR_DOC_TYPE = FILETYPE_EXHIBITOR_DOC;
const AFRAME_PANEL_MEDIA_TYPE = FILETYPE_AFRAMEPANEL_MEDIA;
const CONFIGURATION_FILE_TYPE = FILETYPE_CONFIGURATION;
const DESIGN_CONFIG_FILE_TYPE = FILETYPE_DESIGN_CONFIGURATION;
const ELCOURSE_POSTER_FILE_TYPE = FILETYPE_ELCOURSE_POSTER;
const ELTASK_POSTER_FILE_TYPE = FILETYPE_ELTASK_POSTER;
const ELTASK_VIDEO_FILE_TYPE = FILETYPE_ELTASK_VIDEO;
const ELEXAM_VIDEO_FILE_TYPE = FILETYPE_ELEXAM_VIDEO;
const ELEXAM_ANSWER_FILE_TYPE = FILETYPE_ELEXAM_ANSWER_FILE;
const VIDEOLIBRARY_VIDEO_TYPE = FILETYPE_VIDEOLIBRARYVIDEO_MEDIA;
const VIDEOLIBRARY_POSTER_FILE_TYPE = FILETYPE_VIDEOLIBRARY_POSTER;
const VIDEOGALLERY_VIDEO_TYPE = FILETYPE_VIDEOGALLERYVIDEO_MEDIA;
const VIDEOGALLERY_POSTER_FILE_TYPE = FILETYPE_VIDEOGALLERY_POSTER;
const DOCUMENT_HUB_FILETYPE = FILETYPE_DOCUMENT_FILE;
const NEWSFEED_MEDIA_FILE_TYPE = FILETYPE_NEWSFEED_MEDIA;
const COMMON_COMMENT_MEDIA_FILE_TYPE = FILETYPE_COMMON_COMMENT_MEDIA;
const CHAT_MESSAGE_MEDIA_FILE_TYPE = FILETYPE_CHAT_MESSAGE_MEDIA;

const ROLES = Role.ROLE;

export {
    FILE_TYPE,
    FILE_TYPE_INFO,
    VOTE_OPTION_POSTER_TYPE,
    VoteOptionFileInfo,
    USER_PROFILE_TYPE,
    UserProfileFileInfo,
    EXHIBITOR_POSTER_TYPE,
    ExhibitorPosterFileInfo,
    EXHIBITOR_LOGO_POSTER_TYPE,
    ExhibitorLogoPosterFileInfo,
    ExhibitorVideoFileInfo,
    EXHIBITOR_VIDEO_TYPE,
    EXHIBITOR_PRODUCT_POSTER_TYPE,
    ExhibitorProductPosterFileInfo,
    ConferencePosterFileInfo,
    CONFERENCE_POSTER_TYPE,
    ExhibitorDocFileInfo,
    EXHIBITOR_DOC_TYPE,
    AFramePanelMediaFileInfo,
    AFRAME_PANEL_MEDIA_TYPE,
    DesignConfigurationFileInfo,
    ImportFileFileInfo,
    SessionPosterFileInfo,
    CONFIGURATION_FILE_TYPE,
    DESIGN_CONFIG_FILE_TYPE,
    ELCoursePosterFileInfo,
    ELCOURSE_POSTER_FILE_TYPE,
    ELTaskVideoFileInfo,
    ELTASK_VIDEO_FILE_TYPE,
    ELTaskPosterFileInfo,
    ELTASK_POSTER_FILE_TYPE,
    ELExamVideoFileInfo,
    ELExamAnswerFileInfo,
    ELEXAM_VIDEO_FILE_TYPE,
    ELEXAM_ANSWER_FILE_TYPE,
    VideoLibrary,
    VideoLibraryVideoFileInfo,
    VideoGalleryVideoFileInfo,
    VIDEOLIBRARY_VIDEO_TYPE,
    VIDEOGALLERY_VIDEO_TYPE,
    VideoLibraryPosterFileInfo,
    VideoGalleryPosterFileInfo,
    VIDEOLIBRARY_POSTER_FILE_TYPE,
    VIDEOGALLERY_POSTER_FILE_TYPE,
    DocumentHubFileInfo,
    DOCUMENT_HUB_FILETYPE,
    NEWSFEED_MEDIA_FILE_TYPE,
    COMMON_COMMENT_MEDIA_FILE_TYPE,
    CHAT_MESSAGE_MEDIA_FILE_TYPE,
    BackgroundPosterFileInfo,
    NewsFeedMediaFileInfo,
    CommonCommentMediaFileInfo,
    ChatMessageMediaFileInfo,
    ContainerPosterFileInfo,
    ContainerLogoFileInfo,
};

export {
    R,
    ROUTES,
    CONSTANTS,
    C,
    VOTE_QUESTION_TYPE,
    VOTE_RESULT_TYPE,
    VOTE_QUESTION_CHART_TYPE,
    VOTE_QUESTION_SLIDER_STYLE,
    STREAM_TYPE,
    CARD_SIZE,
    CARD_TYPE,
    ROLES,
    TASKTYPE,
    VIDEOTASKTYPE,
    STREAMTYPE,
    SOLUTIONTYPE,
    QUESTIONTYPE,
    EXAM_LEVEL,
    STATUS_TASK_ATTEMPT,
    STATUS_EXAM_ATTEMPT,
    RESULT_STATUS_EXAM_ATTEMPT,
    EVENT,
    SURVEY_TRIGGER_DISPLAY_TYPE,
    SURVEY_QUESTION_TYPE,
};

const { ETKEY, ETKEYINFO } = EmailTemplate;

export { ETKEY, ETKEYINFO };

const { PROVIDER: MEETING_PROVIDER, TYPE: MEETING_TYPE } = Meeting;

const { STATUS: MEETING_BOOKING_STATUS } = MeetingBooking;

export { MEETING_PROVIDER, MEETING_TYPE, MEETING_BOOKING_STATUS };

const { TIMEZONE } = User;

export { TIMEZONE };

const {
    STREAMTYPE: {
        STREAMTYPE_YOUTUBE: YOUTUBE,
        STREAMTYPE_VIMEO: VIMEO,
        STREAMTYPE_SWISSCOM: SWISSCOM,
        STREAMTYPE_KNOVIO: KNOVIO,
        STREAMTYPE_ZOOM: ZOOM,
        STREAMTYPE_WEBEX: WEBEX,
        STREAMTYPE_FILE: FILE,
        STREAMTYPE_EXPERTSHARE: EXPERTSHARE,
        STREAMTYPE_QUMU: QUMU,
    },
} = Session;

const STREAM = {
    YOUTUBE,
    VIMEO,
    SWISSCOM,
    KNOVIO,
    ZOOM,
    WEBEX,
    FILE,
    EXPERTSHARE,
    QUMU,
};

export const SectionLimit = {
    COURSE: 8,
    CONTAINER: 8,
    CONFERENCE: 8,
    VIDEO_GALLERY: 8,
    ATTENDEE: 12,
}

export const AppUserListDisplayLength = 3
export const ShowMoreTextCharLength = 150

export { STREAM };
