import React, { FC, PropsWithChildren } from "react";
import { PrimitiveObject } from "../../models";
import "./assets/scss/style.scss";

export interface AppContainerProps extends PropsWithChildren {
    className?: string;
    id?: string;
    style?: PrimitiveObject;
}

export const AppContainer: FC<AppContainerProps> = ({
    children,
    className = "",
    id,
    style,
}): JSX.Element => {
    return (
        <div id={id} className={`p-container ${className}`} style={style}>
            {children}
        </div>
    );
};
