import React, { useEffect, useRef } from "react";

import { AppBadgeBackground } from "../AppBadgeBackground";
import { AppBadgeArcText } from "./AppBadgeArcText";

import "./assets/scss/style.scss";

interface AppBadgeAvatarProps {
    text: string;
    isOnline: boolean;
}

const arc = 78;

export const AppBadgeAvatar: React.FC<AppBadgeAvatarProps> = ({
    text,
    isOnline,
}) => {
    const badgeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (badgeRef.current) {
            badgeRef.current.style.transform =
                "translate(-50%, calc(-50%)) rotate(-90deg)";
            badgeRef.current.style.marginLeft = "5px";
        }
    }, []);

    return (
        <div className="badge-avatar">
            <div ref={badgeRef} className="badge-rotate">
                <AppBadgeArcText
                    text={
                        text.length > 15
                            ? `${text.slice(0, 15).toUpperCase()}...`
                            : text
                    }
                    direction={1}
                    arc={arc}
                    class={"badge-avatar--container"}
                />
            </div>
            <div className={`badge-avatar--background`}>
                <AppBadgeBackground
                    text={text.slice(0, 15)}
                    isOnline={isOnline}
                />
            </div>
        </div>
    );
};
