import { PrimitiveObject } from "../models";

export const getBGStyle = (
    basePath: string,
    image: string,
    placeholder = ""
): PrimitiveObject => {
    if (image && image !== "") {
        return {
            backgroundImage: `url(${basePath}/${image})`,
        };
    }
    if (placeholder) {
        return {
            backgroundImage: `url(${placeholder})`,
        };
    }
    return {};
};

export const resolveImageWithStyle = (
    basePath: string,
    image: string,
    placeholder = ""
): PrimitiveObject => {
    const bgStyle = getBGStyle(basePath, image, placeholder);
    const css =
        image === ""
            ? {
                  ...bgStyle,
                  backgroundSize: "50%",
                  backgroundPosition: "center",
              }
            : bgStyle;
    return css;
};

export const resolveImage = (
    basePath: string,
    image: string,
    placeholder = ""
): string => {
    if (image && image !== "") {
        return `${basePath}/${image}`;
    }
    return placeholder;
};
