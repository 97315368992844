import React, { FC } from "react";
import { useBuildAssetPath } from "../../hooks";
import { DesignConfigurationFileInfo } from "../../../config";
import { useGlobalData } from "../../contexts";
import { resolveImage } from "../../utils";
import "./assets/scss/styles.scss";
import placeHolderBanner from "./assets/images/banner.jpg";

export const AppBanner: FC = (): JSX.Element => {
    const {
        mainContainer,
        designConfig: { genPublicPageBanner },
        configuration: { publicPageBannerMainText, publicPageBannerSubText },
    } = useGlobalData();
    // image
    const basePath = useBuildAssetPath(
        DesignConfigurationFileInfo,
        mainContainer
    );
    const banner = resolveImage(
        basePath,
        genPublicPageBanner,
        placeHolderBanner
    );

    return (
        <div className="banner-slider">
            <div className="banner-slider--content">
                <div className="text">
                    <h1 className="title">{publicPageBannerMainText}</h1>
                    <h4 className="sub-title">{publicPageBannerSubText}</h4>
                </div>
                <img src={banner} />
            </div>
        </div>
    );
};
