import React from "react";
import blankDataSvg from "../../assets/images/blankDataThumb.svg";
import { useTranslation } from "../../hooks";

import "./assets/scss/style.scss";

export const AppBlankDataHelper = () => {
    const { t } = useTranslation();

    const style = {
        backgroundImage: `url(${blankDataSvg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        width: "100px",
        height: "100px",
        display: "block",
    };

    return (
        <div className="blankNoData-container p-5 text-center w-100">
            <i className="image text-center mx-auto mb-3" style={style}></i>
            <span className="title">{t("common.message:noDataFound")}</span>
        </div>
    );
};
