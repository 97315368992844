interface UserJobCompany {
    displayJobCompany: (
        jobTitle: string,
        company: string,
        separator?: string
    ) => string;
}

export const useUserJobCompany = (): UserJobCompany => {
    const displayJobCompany = (
        jobTitle: string,
        company: string,
        separator = "@"
    ): string => {
        const jobTitleString = jobTitle || "";

        const companyNameString = company || "";

        if (separator !== "@" && jobTitleString) {
            return `${jobTitleString}${separator} ${companyNameString}`;
        }

        return `${jobTitleString} ${
            companyNameString ? separator : ""
        }${companyNameString}`;
    };

    return {
        displayJobCompany,
    };
};
