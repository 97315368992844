import { axios, i18n } from "./config";
import appRouter from "./routers";
import { ModuleRouter } from "./models";

const appRouters: ModuleRouter[] = [...appRouter];

// initialize global packages and libraries
i18n.init(null);
axios.init();

export { appRouters };
