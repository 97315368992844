import React, { cloneElement, FC, ReactElement, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// eslint-disable-next-line import/named
import { Placement } from "react-bootstrap/esm/Overlay";
import "./assets/scss/style.scss";

export interface AppTextHoverTooltipProps {
    text: string;
    className?: string;
    placement?: Placement;
    children: ReactElement;
}

export const AppTextHoverTooltip: FC<AppTextHoverTooltipProps> = ({
    text,
    className = "",
    placement = "top-start",
    children,
}) => {
    const [show, setShow] = useState(false);
    const elementRef = useRef<HTMLElement>(null);

    return (
        <>
            <OverlayTrigger
                show={show}
                placement={placement}
                flip={!placement.includes("auto")}
                overlay={
                    <Tooltip id={text} className={className}>
                        {text}
                    </Tooltip>
                }
            >
                {cloneElement(children, {
                    ref: elementRef,
                    onMouseEnter: () => setShow(true),
                    onMouseLeave: () => setShow(false),
                })}
            </OverlayTrigger>
        </>
    );
};
