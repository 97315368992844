import React, { StrictMode } from "react";
import { LocationProvider } from "@reach/router";
import ReactDOM from "react-dom/client";
import App from "./AppModule/App";
import { APP_ENV } from "./AppModule/config";
import { GlobalProvider } from "./AppModule/contexts";
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const app = (
    <LocationProvider>
        <RecoilRoot>
            <GlobalProvider>
                <App />
            </GlobalProvider>
        </RecoilRoot>
    </LocationProvider>
);

root.render(APP_ENV === "development" ? app : <StrictMode>{app}</StrictMode>);
